import { GraphQLClient, RequestOptions } from 'graphql-request';
import { GraphQLError, print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Bytes: { input: any; output: any; }
  Int8: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
};

export enum Aggregation_Interval {
  Day = 'day',
  Hour = 'hour'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export type CcCollateral = {
  __typename?: 'CCCollateral';
  activeCollateral: Scalars['BigInt']['output'];
  epoch?: Maybe<EpochStatistic>;
  id: Scalars['String']['output'];
  totalWithdrawnCollateral: Scalars['BigInt']['output'];
  waitStartCollateral: Scalars['BigInt']['output'];
  withdrawableCollateral: Scalars['BigInt']['output'];
};

export type CcCollateral_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  activeCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  activeCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  activeCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<CcCollateral_Filter>>>;
  epoch?: InputMaybe<Scalars['String']['input']>;
  epoch_?: InputMaybe<EpochStatistic_Filter>;
  epoch_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_gt?: InputMaybe<Scalars['String']['input']>;
  epoch_gte?: InputMaybe<Scalars['String']['input']>;
  epoch_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_lt?: InputMaybe<Scalars['String']['input']>;
  epoch_lte?: InputMaybe<Scalars['String']['input']>;
  epoch_not?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<CcCollateral_Filter>>>;
  totalWithdrawnCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalWithdrawnCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalWithdrawnCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  waitStartCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  waitStartCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  waitStartCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawableCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawableCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawableCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum CcCollateral_OrderBy {
  ActiveCollateral = 'activeCollateral',
  Epoch = 'epoch',
  EpochId = 'epoch__id',
  EpochStartBlock = 'epoch__startBlock',
  EpochStartTimestamp = 'epoch__startTimestamp',
  Id = 'id',
  TotalWithdrawnCollateral = 'totalWithdrawnCollateral',
  WaitStartCollateral = 'waitStartCollateral',
  WithdrawableCollateral = 'withdrawableCollateral'
}

export type CcCount = {
  __typename?: 'CCCount';
  ccActiveCount: Scalars['Int']['output'];
  ccFailedCount: Scalars['Int']['output'];
  ccInactiveCount: Scalars['Int']['output'];
  ccRemovedCount: Scalars['Int']['output'];
  ccWaitDelegationCount: Scalars['Int']['output'];
  ccWaitStartCount: Scalars['Int']['output'];
  epoch?: Maybe<EpochStatistic>;
  id: Scalars['String']['output'];
  staker?: Maybe<Staker>;
};

export type CcCount_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CcCount_Filter>>>;
  ccActiveCount?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccActiveCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccActiveCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccFailedCount?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccFailedCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccFailedCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccInactiveCount?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccInactiveCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccInactiveCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccRemovedCount?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccRemovedCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccRemovedCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccWaitDelegationCount?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccWaitDelegationCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccWaitDelegationCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccWaitStartCount?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_gt?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_gte?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ccWaitStartCount_lt?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_lte?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_not?: InputMaybe<Scalars['Int']['input']>;
  ccWaitStartCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  epoch?: InputMaybe<Scalars['String']['input']>;
  epoch_?: InputMaybe<EpochStatistic_Filter>;
  epoch_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_gt?: InputMaybe<Scalars['String']['input']>;
  epoch_gte?: InputMaybe<Scalars['String']['input']>;
  epoch_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_lt?: InputMaybe<Scalars['String']['input']>;
  epoch_lte?: InputMaybe<Scalars['String']['input']>;
  epoch_not?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<CcCount_Filter>>>;
  staker?: InputMaybe<Scalars['String']['input']>;
  staker_?: InputMaybe<Staker_Filter>;
  staker_contains?: InputMaybe<Scalars['String']['input']>;
  staker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_gt?: InputMaybe<Scalars['String']['input']>;
  staker_gte?: InputMaybe<Scalars['String']['input']>;
  staker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_lt?: InputMaybe<Scalars['String']['input']>;
  staker_lte?: InputMaybe<Scalars['String']['input']>;
  staker_not?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum CcCount_OrderBy {
  CcActiveCount = 'ccActiveCount',
  CcFailedCount = 'ccFailedCount',
  CcInactiveCount = 'ccInactiveCount',
  CcRemovedCount = 'ccRemovedCount',
  CcWaitDelegationCount = 'ccWaitDelegationCount',
  CcWaitStartCount = 'ccWaitStartCount',
  Epoch = 'epoch',
  EpochId = 'epoch__id',
  EpochStartBlock = 'epoch__startBlock',
  EpochStartTimestamp = 'epoch__startTimestamp',
  Id = 'id',
  Staker = 'staker',
  StakerFltBalance = 'staker__fltBalance',
  StakerId = 'staker__id',
  StakerSlashedCollateral = 'staker__slashedCollateral',
  StakerStakedCollateral = 'staker__stakedCollateral',
  StakerWithdrawnCollateral = 'staker__withdrawnCollateral'
}

export type CCsReward = {
  __typename?: 'CCsReward';
  epoch?: Maybe<EpochStatistic>;
  id: Scalars['String']['output'];
  provider?: Maybe<Provider>;
  staker?: Maybe<Staker>;
  stakerTotalCapacityRewards: Scalars['BigInt']['output'];
  stakerUnlockedCapacityRewards: Scalars['BigInt']['output'];
  stakerWithdrawnCapacityRewards: Scalars['BigInt']['output'];
  totalCapacityRewards: Scalars['BigInt']['output'];
  totalDealStakerRewards: Scalars['BigInt']['output'];
  unlockedCapacityRewards: Scalars['BigInt']['output'];
  unlockedDealStakerRewards: Scalars['BigInt']['output'];
  withdrawnCapacityRewards: Scalars['BigInt']['output'];
  withdrawnDealStakerRewards: Scalars['BigInt']['output'];
};

export type CCsReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CCsReward_Filter>>>;
  epoch?: InputMaybe<Scalars['String']['input']>;
  epoch_?: InputMaybe<EpochStatistic_Filter>;
  epoch_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_gt?: InputMaybe<Scalars['String']['input']>;
  epoch_gte?: InputMaybe<Scalars['String']['input']>;
  epoch_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_lt?: InputMaybe<Scalars['String']['input']>;
  epoch_lte?: InputMaybe<Scalars['String']['input']>;
  epoch_not?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains?: InputMaybe<Scalars['String']['input']>;
  epoch_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epoch_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with?: InputMaybe<Scalars['String']['input']>;
  epoch_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<CCsReward_Filter>>>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker?: InputMaybe<Scalars['String']['input']>;
  stakerTotalCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakerTotalCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakerTotalCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakerUnlockedCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakerUnlockedCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakerUnlockedCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakerWithdrawnCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakerWithdrawnCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakerWithdrawnCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  staker_?: InputMaybe<Staker_Filter>;
  staker_contains?: InputMaybe<Scalars['String']['input']>;
  staker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_gt?: InputMaybe<Scalars['String']['input']>;
  staker_gte?: InputMaybe<Scalars['String']['input']>;
  staker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_lt?: InputMaybe<Scalars['String']['input']>;
  staker_lte?: InputMaybe<Scalars['String']['input']>;
  staker_not?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  totalCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDealStakerRewards?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDealStakerRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDealStakerRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unlockedCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unlockedCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unlockedDealStakerRewards?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unlockedDealStakerRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  unlockedDealStakerRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnCapacityRewards?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnCapacityRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCapacityRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnDealStakerRewards?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnDealStakerRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnDealStakerRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum CCsReward_OrderBy {
  Epoch = 'epoch',
  EpochId = 'epoch__id',
  EpochStartBlock = 'epoch__startBlock',
  EpochStartTimestamp = 'epoch__startTimestamp',
  Id = 'id',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs',
  Staker = 'staker',
  StakerTotalCapacityRewards = 'stakerTotalCapacityRewards',
  StakerUnlockedCapacityRewards = 'stakerUnlockedCapacityRewards',
  StakerWithdrawnCapacityRewards = 'stakerWithdrawnCapacityRewards',
  StakerFltBalance = 'staker__fltBalance',
  StakerId = 'staker__id',
  StakerSlashedCollateral = 'staker__slashedCollateral',
  StakerStakedCollateral = 'staker__stakedCollateral',
  StakerWithdrawnCollateral = 'staker__withdrawnCollateral',
  TotalCapacityRewards = 'totalCapacityRewards',
  TotalDealStakerRewards = 'totalDealStakerRewards',
  UnlockedCapacityRewards = 'unlockedCapacityRewards',
  UnlockedDealStakerRewards = 'unlockedDealStakerRewards',
  WithdrawnCapacityRewards = 'withdrawnCapacityRewards',
  WithdrawnDealStakerRewards = 'withdrawnDealStakerRewards'
}

/**
 * To represent that Peer has capacity commitment for some time.
 *
 */
export type CapacityCommitment = {
  __typename?: 'CapacityCommitment';
  activeUnitCount: Scalars['Int']['output'];
  ccRewardsTotal: Scalars['BigInt']['output'];
  ccRewardsUnlocked: Scalars['BigInt']['output'];
  ccRewardsWithdrawn: Scalars['BigInt']['output'];
  collateralPerUnit: Scalars['BigInt']['output'];
  collateralWithdrawn: Scalars['Boolean']['output'];
  computeUnits?: Maybe<Array<CapacityCommitmentToComputeUnit>>;
  computeUnitsCount: Scalars['Int']['output'];
  /** timestamp of creation of CC. It does not mean that CC is started. */
  createdAt: Scalars['BigInt']['output'];
  dealStakerRewardsTotal: Scalars['BigInt']['output'];
  dealStakerRewardsUnlocked: Scalars['BigInt']['output'];
  dealStakerRewardsWithdrawn: Scalars['BigInt']['output'];
  delegator?: Maybe<Staker>;
  /** If CC deleted before collateral deposited. */
  deleted: Scalars['Boolean']['output'];
  depositedAt: Scalars['BigInt']['output'];
  duration: Scalars['BigInt']['output'];
  endEpoch: Scalars['BigInt']['output'];
  exitedUnitCount: Scalars['Int']['output'];
  failedEpoch: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  nextAdditionalActiveUnitCount: Scalars['Int']['output'];
  /** Calculated in subgraph field: next failed epoch means the next epoch without proofs submitted when CC declared to be Failed. */
  nextCCFailedEpoch: Scalars['BigInt']['output'];
  peer: Peer;
  provider: Provider;
  /** Amount of tokens required to activate commitment */
  requiredCollateral: Scalars['BigInt']['output'];
  /** This field represents Ratio [0, 1] only when it is divided by PRECISION constant of Core contract. */
  rewardDelegatorRate: Scalars['BigInt']['output'];
  rewardRatePerCollateral: Scalars['BigInt']['output'];
  snapshotEpoch: Scalars['BigInt']['output'];
  startEpoch: Scalars['BigInt']['output'];
  /** This status represents last stored status on chain (status that does not depends on the current epoch some how). */
  status?: Maybe<CapacityCommitmentStatus>;
  storage: CapacityStorage;
  submittedProofsCount: Scalars['Int']['output'];
  /** Collateral of native token (FLT) that has been deposited. */
  totalCollateral: Scalars['BigInt']['output'];
  totalFailCount: Scalars['Int']['output'];
  withdrawnAt: Scalars['BigInt']['output'];
};


/**
 * To represent that Peer has capacity commitment for some time.
 *
 */
export type CapacityCommitmentComputeUnitsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitmentToComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CapacityCommitmentToComputeUnit_Filter>;
};

/** To represent statistics per epoch. This entity represent evaluation of CommitmentStatsUpdated events during time (epoch). */
export type CapacityCommitmentStatsPerEpoch = {
  __typename?: 'CapacityCommitmentStatsPerEpoch';
  activeUnitCount: Scalars['Int']['output'];
  capacityCommitment: CapacityCommitment;
  computeUnitsWithMinRequiredProofsSubmittedCounter: Scalars['Int']['output'];
  cuIds?: Maybe<Array<Scalars['String']['output']>>;
  currentCCNextCCFailedEpoch: Scalars['BigInt']['output'];
  /** Additional field to support ordering by epoch. */
  epoch: Scalars['BigInt']['output'];
  epochStatistic: EpochStatistic;
  exitedUnitCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  nextAdditionalActiveUnitCount: Scalars['Int']['output'];
  submittedProofsCount: Scalars['Int']['output'];
  submittedProofsPerCU?: Maybe<Array<Scalars['BigInt']['output']>>;
  totalFailCount: Scalars['Int']['output'];
};

export type CapacityCommitmentStatsPerEpoch_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  activeUnitCount?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  and?: InputMaybe<Array<InputMaybe<CapacityCommitmentStatsPerEpoch_Filter>>>;
  capacityCommitment?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_?: InputMaybe<CapacityCommitment_Filter>;
  capacityCommitment_contains?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_ends_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_gt?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_gte?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_in?: InputMaybe<Array<Scalars['String']['input']>>;
  capacityCommitment_lt?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_lte?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_contains?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  capacityCommitment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_starts_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsWithMinRequiredProofsSubmittedCounter_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  cuIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cuIds_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  cuIds_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  cuIds_not?: InputMaybe<Array<Scalars['String']['input']>>;
  cuIds_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  cuIds_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCCNextCCFailedEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCNextCCFailedEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  epoch?: InputMaybe<Scalars['BigInt']['input']>;
  epochStatistic?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_?: InputMaybe<EpochStatistic_Filter>;
  epochStatistic_contains?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_ends_with?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_gt?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_gte?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epochStatistic_lt?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_lte?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_contains?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  epochStatistic_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_starts_with?: InputMaybe<Scalars['String']['input']>;
  epochStatistic_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  epoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  epoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  epoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  epoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  epoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  epoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  epoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  exitedUnitCount?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  exitedUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextAdditionalActiveUnitCount?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextAdditionalActiveUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CapacityCommitmentStatsPerEpoch_Filter>>>;
  submittedProofsCount?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_gt?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_gte?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  submittedProofsCount_lt?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_lte?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_not?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  submittedProofsPerCU?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  submittedProofsPerCU_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  submittedProofsPerCU_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  submittedProofsPerCU_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  submittedProofsPerCU_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  submittedProofsPerCU_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalFailCount?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_gt?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_gte?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalFailCount_lt?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_lte?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_not?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum CapacityCommitmentStatsPerEpoch_OrderBy {
  ActiveUnitCount = 'activeUnitCount',
  CapacityCommitment = 'capacityCommitment',
  CapacityCommitmentActiveUnitCount = 'capacityCommitment__activeUnitCount',
  CapacityCommitmentCcRewardsTotal = 'capacityCommitment__ccRewardsTotal',
  CapacityCommitmentCcRewardsUnlocked = 'capacityCommitment__ccRewardsUnlocked',
  CapacityCommitmentCcRewardsWithdrawn = 'capacityCommitment__ccRewardsWithdrawn',
  CapacityCommitmentCollateralPerUnit = 'capacityCommitment__collateralPerUnit',
  CapacityCommitmentCollateralWithdrawn = 'capacityCommitment__collateralWithdrawn',
  CapacityCommitmentComputeUnitsCount = 'capacityCommitment__computeUnitsCount',
  CapacityCommitmentCreatedAt = 'capacityCommitment__createdAt',
  CapacityCommitmentDealStakerRewardsTotal = 'capacityCommitment__dealStakerRewardsTotal',
  CapacityCommitmentDealStakerRewardsUnlocked = 'capacityCommitment__dealStakerRewardsUnlocked',
  CapacityCommitmentDealStakerRewardsWithdrawn = 'capacityCommitment__dealStakerRewardsWithdrawn',
  CapacityCommitmentDeleted = 'capacityCommitment__deleted',
  CapacityCommitmentDepositedAt = 'capacityCommitment__depositedAt',
  CapacityCommitmentDuration = 'capacityCommitment__duration',
  CapacityCommitmentEndEpoch = 'capacityCommitment__endEpoch',
  CapacityCommitmentExitedUnitCount = 'capacityCommitment__exitedUnitCount',
  CapacityCommitmentFailedEpoch = 'capacityCommitment__failedEpoch',
  CapacityCommitmentId = 'capacityCommitment__id',
  CapacityCommitmentNextAdditionalActiveUnitCount = 'capacityCommitment__nextAdditionalActiveUnitCount',
  CapacityCommitmentNextCcFailedEpoch = 'capacityCommitment__nextCCFailedEpoch',
  CapacityCommitmentRequiredCollateral = 'capacityCommitment__requiredCollateral',
  CapacityCommitmentRewardDelegatorRate = 'capacityCommitment__rewardDelegatorRate',
  CapacityCommitmentRewardRatePerCollateral = 'capacityCommitment__rewardRatePerCollateral',
  CapacityCommitmentSnapshotEpoch = 'capacityCommitment__snapshotEpoch',
  CapacityCommitmentStartEpoch = 'capacityCommitment__startEpoch',
  CapacityCommitmentStatus = 'capacityCommitment__status',
  CapacityCommitmentSubmittedProofsCount = 'capacityCommitment__submittedProofsCount',
  CapacityCommitmentTotalCollateral = 'capacityCommitment__totalCollateral',
  CapacityCommitmentTotalFailCount = 'capacityCommitment__totalFailCount',
  CapacityCommitmentWithdrawnAt = 'capacityCommitment__withdrawnAt',
  ComputeUnitsWithMinRequiredProofsSubmittedCounter = 'computeUnitsWithMinRequiredProofsSubmittedCounter',
  CuIds = 'cuIds',
  CurrentCcNextCcFailedEpoch = 'currentCCNextCCFailedEpoch',
  Epoch = 'epoch',
  EpochStatistic = 'epochStatistic',
  EpochStatisticId = 'epochStatistic__id',
  EpochStatisticStartBlock = 'epochStatistic__startBlock',
  EpochStatisticStartTimestamp = 'epochStatistic__startTimestamp',
  ExitedUnitCount = 'exitedUnitCount',
  Id = 'id',
  NextAdditionalActiveUnitCount = 'nextAdditionalActiveUnitCount',
  SubmittedProofsCount = 'submittedProofsCount',
  SubmittedProofsPerCu = 'submittedProofsPerCU',
  TotalFailCount = 'totalFailCount'
}

export enum CapacityCommitmentStatus {
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  Removed = 'Removed',
  WaitDelegation = 'WaitDelegation',
  WaitStart = 'WaitStart'
}

export type CapacityCommitmentToComputeUnit = {
  __typename?: 'CapacityCommitmentToComputeUnit';
  capacityCommitment: CapacityCommitment;
  computeUnit: ComputeUnit;
  id: Scalars['ID']['output'];
};

export type CapacityCommitmentToComputeUnit_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CapacityCommitmentToComputeUnit_Filter>>>;
  capacityCommitment?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_?: InputMaybe<CapacityCommitment_Filter>;
  capacityCommitment_contains?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_ends_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_gt?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_gte?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_in?: InputMaybe<Array<Scalars['String']['input']>>;
  capacityCommitment_lt?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_lte?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_contains?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  capacityCommitment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_starts_with?: InputMaybe<Scalars['String']['input']>;
  capacityCommitment_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit?: InputMaybe<Scalars['String']['input']>;
  computeUnit_?: InputMaybe<ComputeUnit_Filter>;
  computeUnit_contains?: InputMaybe<Scalars['String']['input']>;
  computeUnit_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit_ends_with?: InputMaybe<Scalars['String']['input']>;
  computeUnit_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit_gt?: InputMaybe<Scalars['String']['input']>;
  computeUnit_gte?: InputMaybe<Scalars['String']['input']>;
  computeUnit_in?: InputMaybe<Array<Scalars['String']['input']>>;
  computeUnit_lt?: InputMaybe<Scalars['String']['input']>;
  computeUnit_lte?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_contains?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  computeUnit_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  computeUnit_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  computeUnit_starts_with?: InputMaybe<Scalars['String']['input']>;
  computeUnit_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CapacityCommitmentToComputeUnit_Filter>>>;
};

export enum CapacityCommitmentToComputeUnit_OrderBy {
  CapacityCommitment = 'capacityCommitment',
  CapacityCommitmentActiveUnitCount = 'capacityCommitment__activeUnitCount',
  CapacityCommitmentCcRewardsTotal = 'capacityCommitment__ccRewardsTotal',
  CapacityCommitmentCcRewardsUnlocked = 'capacityCommitment__ccRewardsUnlocked',
  CapacityCommitmentCcRewardsWithdrawn = 'capacityCommitment__ccRewardsWithdrawn',
  CapacityCommitmentCollateralPerUnit = 'capacityCommitment__collateralPerUnit',
  CapacityCommitmentCollateralWithdrawn = 'capacityCommitment__collateralWithdrawn',
  CapacityCommitmentComputeUnitsCount = 'capacityCommitment__computeUnitsCount',
  CapacityCommitmentCreatedAt = 'capacityCommitment__createdAt',
  CapacityCommitmentDealStakerRewardsTotal = 'capacityCommitment__dealStakerRewardsTotal',
  CapacityCommitmentDealStakerRewardsUnlocked = 'capacityCommitment__dealStakerRewardsUnlocked',
  CapacityCommitmentDealStakerRewardsWithdrawn = 'capacityCommitment__dealStakerRewardsWithdrawn',
  CapacityCommitmentDeleted = 'capacityCommitment__deleted',
  CapacityCommitmentDepositedAt = 'capacityCommitment__depositedAt',
  CapacityCommitmentDuration = 'capacityCommitment__duration',
  CapacityCommitmentEndEpoch = 'capacityCommitment__endEpoch',
  CapacityCommitmentExitedUnitCount = 'capacityCommitment__exitedUnitCount',
  CapacityCommitmentFailedEpoch = 'capacityCommitment__failedEpoch',
  CapacityCommitmentId = 'capacityCommitment__id',
  CapacityCommitmentNextAdditionalActiveUnitCount = 'capacityCommitment__nextAdditionalActiveUnitCount',
  CapacityCommitmentNextCcFailedEpoch = 'capacityCommitment__nextCCFailedEpoch',
  CapacityCommitmentRequiredCollateral = 'capacityCommitment__requiredCollateral',
  CapacityCommitmentRewardDelegatorRate = 'capacityCommitment__rewardDelegatorRate',
  CapacityCommitmentRewardRatePerCollateral = 'capacityCommitment__rewardRatePerCollateral',
  CapacityCommitmentSnapshotEpoch = 'capacityCommitment__snapshotEpoch',
  CapacityCommitmentStartEpoch = 'capacityCommitment__startEpoch',
  CapacityCommitmentStatus = 'capacityCommitment__status',
  CapacityCommitmentSubmittedProofsCount = 'capacityCommitment__submittedProofsCount',
  CapacityCommitmentTotalCollateral = 'capacityCommitment__totalCollateral',
  CapacityCommitmentTotalFailCount = 'capacityCommitment__totalFailCount',
  CapacityCommitmentWithdrawnAt = 'capacityCommitment__withdrawnAt',
  ComputeUnit = 'computeUnit',
  ComputeUnitCreatedAt = 'computeUnit__createdAt',
  ComputeUnitDeleted = 'computeUnit__deleted',
  ComputeUnitId = 'computeUnit__id',
  Id = 'id'
}

export type CapacityCommitment_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  activeUnitCount?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  and?: InputMaybe<Array<InputMaybe<CapacityCommitment_Filter>>>;
  ccRewardsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ccRewardsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ccRewardsUnlocked?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ccRewardsUnlocked_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_not?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsUnlocked_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ccRewardsWithdrawn?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ccRewardsWithdrawn_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_not?: InputMaybe<Scalars['BigInt']['input']>;
  ccRewardsWithdrawn_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  collateralPerUnit?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  collateralPerUnit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_not?: InputMaybe<Scalars['BigInt']['input']>;
  collateralPerUnit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  collateralWithdrawn?: InputMaybe<Scalars['Boolean']['input']>;
  collateralWithdrawn_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  collateralWithdrawn_not?: InputMaybe<Scalars['Boolean']['input']>;
  collateralWithdrawn_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  computeUnitsCount?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsCount_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnits_?: InputMaybe<CapacityCommitmentToComputeUnit_Filter>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsUnlocked?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_gt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_gte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsUnlocked_lt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_lte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_not?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsUnlocked_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsWithdrawn?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_gt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_gte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealStakerRewardsWithdrawn_lt?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_lte?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_not?: InputMaybe<Scalars['BigInt']['input']>;
  dealStakerRewardsWithdrawn_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  delegator?: InputMaybe<Scalars['String']['input']>;
  delegator_?: InputMaybe<Staker_Filter>;
  delegator_contains?: InputMaybe<Scalars['String']['input']>;
  delegator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  delegator_ends_with?: InputMaybe<Scalars['String']['input']>;
  delegator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  delegator_gt?: InputMaybe<Scalars['String']['input']>;
  delegator_gte?: InputMaybe<Scalars['String']['input']>;
  delegator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  delegator_lt?: InputMaybe<Scalars['String']['input']>;
  delegator_lte?: InputMaybe<Scalars['String']['input']>;
  delegator_not?: InputMaybe<Scalars['String']['input']>;
  delegator_not_contains?: InputMaybe<Scalars['String']['input']>;
  delegator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  delegator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  delegator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  delegator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  delegator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  delegator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  delegator_starts_with?: InputMaybe<Scalars['String']['input']>;
  delegator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  deleted_not?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  depositedAt?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  depositedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  depositedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  endEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  endEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  endEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  exitedUnitCount?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  exitedUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  exitedUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failedEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  failedEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  failedEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextAdditionalActiveUnitCount?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_gt?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_gte?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextAdditionalActiveUnitCount_lt?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_lte?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_not?: InputMaybe<Scalars['Int']['input']>;
  nextAdditionalActiveUnitCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextCCFailedEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nextCCFailedEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  nextCCFailedEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CapacityCommitment_Filter>>>;
  peer?: InputMaybe<Scalars['String']['input']>;
  peer_?: InputMaybe<Peer_Filter>;
  peer_contains?: InputMaybe<Scalars['String']['input']>;
  peer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_gt?: InputMaybe<Scalars['String']['input']>;
  peer_gte?: InputMaybe<Scalars['String']['input']>;
  peer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_lt?: InputMaybe<Scalars['String']['input']>;
  peer_lte?: InputMaybe<Scalars['String']['input']>;
  peer_not?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  requiredCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  requiredCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  requiredCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardDelegatorRate?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardDelegatorRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  rewardDelegatorRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardRatePerCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardRatePerCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatePerCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  snapshotEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  snapshotEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  snapshotEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  startEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  status?: InputMaybe<CapacityCommitmentStatus>;
  status_in?: InputMaybe<Array<CapacityCommitmentStatus>>;
  status_not?: InputMaybe<CapacityCommitmentStatus>;
  status_not_in?: InputMaybe<Array<CapacityCommitmentStatus>>;
  storage?: InputMaybe<Scalars['String']['input']>;
  storage_?: InputMaybe<CapacityStorage_Filter>;
  storage_contains?: InputMaybe<Scalars['String']['input']>;
  storage_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_ends_with?: InputMaybe<Scalars['String']['input']>;
  storage_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_gt?: InputMaybe<Scalars['String']['input']>;
  storage_gte?: InputMaybe<Scalars['String']['input']>;
  storage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storage_lt?: InputMaybe<Scalars['String']['input']>;
  storage_lte?: InputMaybe<Scalars['String']['input']>;
  storage_not?: InputMaybe<Scalars['String']['input']>;
  storage_not_contains?: InputMaybe<Scalars['String']['input']>;
  storage_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  storage_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  storage_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_starts_with?: InputMaybe<Scalars['String']['input']>;
  storage_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  submittedProofsCount?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_gt?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_gte?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  submittedProofsCount_lt?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_lte?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_not?: InputMaybe<Scalars['Int']['input']>;
  submittedProofsCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalFailCount?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_gt?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_gte?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalFailCount_lt?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_lte?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_not?: InputMaybe<Scalars['Int']['input']>;
  totalFailCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  withdrawnAt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum CapacityCommitment_OrderBy {
  ActiveUnitCount = 'activeUnitCount',
  CcRewardsTotal = 'ccRewardsTotal',
  CcRewardsUnlocked = 'ccRewardsUnlocked',
  CcRewardsWithdrawn = 'ccRewardsWithdrawn',
  CollateralPerUnit = 'collateralPerUnit',
  CollateralWithdrawn = 'collateralWithdrawn',
  ComputeUnits = 'computeUnits',
  ComputeUnitsCount = 'computeUnitsCount',
  CreatedAt = 'createdAt',
  DealStakerRewardsTotal = 'dealStakerRewardsTotal',
  DealStakerRewardsUnlocked = 'dealStakerRewardsUnlocked',
  DealStakerRewardsWithdrawn = 'dealStakerRewardsWithdrawn',
  Delegator = 'delegator',
  DelegatorFltBalance = 'delegator__fltBalance',
  DelegatorId = 'delegator__id',
  DelegatorSlashedCollateral = 'delegator__slashedCollateral',
  DelegatorStakedCollateral = 'delegator__stakedCollateral',
  DelegatorWithdrawnCollateral = 'delegator__withdrawnCollateral',
  Deleted = 'deleted',
  DepositedAt = 'depositedAt',
  Duration = 'duration',
  EndEpoch = 'endEpoch',
  ExitedUnitCount = 'exitedUnitCount',
  FailedEpoch = 'failedEpoch',
  Id = 'id',
  NextAdditionalActiveUnitCount = 'nextAdditionalActiveUnitCount',
  NextCcFailedEpoch = 'nextCCFailedEpoch',
  Peer = 'peer',
  PeerComputeUnitsInDeal = 'peer__computeUnitsInDeal',
  PeerComputeUnitsTotal = 'peer__computeUnitsTotal',
  PeerCurrentCcCollateralDepositedAt = 'peer__currentCCCollateralDepositedAt',
  PeerCurrentCcEndEpoch = 'peer__currentCCEndEpoch',
  PeerCurrentCcNextCcFailedEpoch = 'peer__currentCCNextCCFailedEpoch',
  PeerDeleted = 'peer__deleted',
  PeerId = 'peer__id',
  PeerIdBase58 = 'peer__idBase58',
  PeerIsAnyJoinedDeals = 'peer__isAnyJoinedDeals',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs',
  RequiredCollateral = 'requiredCollateral',
  RewardDelegatorRate = 'rewardDelegatorRate',
  RewardRatePerCollateral = 'rewardRatePerCollateral',
  SnapshotEpoch = 'snapshotEpoch',
  StartEpoch = 'startEpoch',
  Status = 'status',
  Storage = 'storage',
  StorageId = 'storage__id',
  SubmittedProofsCount = 'submittedProofsCount',
  TotalCollateral = 'totalCollateral',
  TotalFailCount = 'totalFailCount',
  WithdrawnAt = 'withdrawnAt'
}

/** Store all capacity ids under index '0' */
export type CapacityStorage = {
  __typename?: 'CapacityStorage';
  capacities: Array<CapacityCommitment>;
  id: Scalars['ID']['output'];
};


/** Store all capacity ids under index '0' */
export type CapacityStorageCapacitiesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitment_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CapacityCommitment_Filter>;
};

export type CapacityStorage_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CapacityStorage_Filter>>>;
  capacities_?: InputMaybe<CapacityCommitment_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CapacityStorage_Filter>>>;
};

export enum CapacityStorage_OrderBy {
  Capacities = 'capacities',
  Id = 'id'
}

export type ComputeUnit = {
  __typename?: 'ComputeUnit';
  createdAt: Scalars['BigInt']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  peer: Peer;
  /** In order to simplify relation for query. */
  provider: Provider;
  worker?: Maybe<Worker>;
};

export type ComputeUnitStat = {
  __typename?: 'ComputeUnitStat';
  activeInCapacityCommitment: Scalars['Int']['output'];
  activeInDeal: Scalars['Int']['output'];
  failed: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  successful: Scalars['Int']['output'];
};

export type ComputeUnitStat_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  activeInCapacityCommitment?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_gt?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_gte?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeInCapacityCommitment_lt?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_lte?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_not?: InputMaybe<Scalars['Int']['input']>;
  activeInCapacityCommitment_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeInDeal?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_gt?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_gte?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeInDeal_lt?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_lte?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_not?: InputMaybe<Scalars['Int']['input']>;
  activeInDeal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  and?: InputMaybe<Array<InputMaybe<ComputeUnitStat_Filter>>>;
  failed?: InputMaybe<Scalars['Int']['input']>;
  failed_gt?: InputMaybe<Scalars['Int']['input']>;
  failed_gte?: InputMaybe<Scalars['Int']['input']>;
  failed_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failed_lt?: InputMaybe<Scalars['Int']['input']>;
  failed_lte?: InputMaybe<Scalars['Int']['input']>;
  failed_not?: InputMaybe<Scalars['Int']['input']>;
  failed_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<ComputeUnitStat_Filter>>>;
  successful?: InputMaybe<Scalars['Int']['input']>;
  successful_gt?: InputMaybe<Scalars['Int']['input']>;
  successful_gte?: InputMaybe<Scalars['Int']['input']>;
  successful_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  successful_lt?: InputMaybe<Scalars['Int']['input']>;
  successful_lte?: InputMaybe<Scalars['Int']['input']>;
  successful_not?: InputMaybe<Scalars['Int']['input']>;
  successful_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ComputeUnitStat_OrderBy {
  ActiveInCapacityCommitment = 'activeInCapacityCommitment',
  ActiveInDeal = 'activeInDeal',
  Failed = 'failed',
  Id = 'id',
  Successful = 'successful'
}

export type ComputeUnit_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ComputeUnit_Filter>>>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  deleted_not?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<ComputeUnit_Filter>>>;
  peer?: InputMaybe<Scalars['String']['input']>;
  peer_?: InputMaybe<Peer_Filter>;
  peer_contains?: InputMaybe<Scalars['String']['input']>;
  peer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_gt?: InputMaybe<Scalars['String']['input']>;
  peer_gte?: InputMaybe<Scalars['String']['input']>;
  peer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_lt?: InputMaybe<Scalars['String']['input']>;
  peer_lte?: InputMaybe<Scalars['String']['input']>;
  peer_not?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker?: InputMaybe<Scalars['String']['input']>;
  worker_?: InputMaybe<Worker_Filter>;
  worker_contains?: InputMaybe<Scalars['String']['input']>;
  worker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_ends_with?: InputMaybe<Scalars['String']['input']>;
  worker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_gt?: InputMaybe<Scalars['String']['input']>;
  worker_gte?: InputMaybe<Scalars['String']['input']>;
  worker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  worker_lt?: InputMaybe<Scalars['String']['input']>;
  worker_lte?: InputMaybe<Scalars['String']['input']>;
  worker_not?: InputMaybe<Scalars['String']['input']>;
  worker_not_contains?: InputMaybe<Scalars['String']['input']>;
  worker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  worker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  worker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  worker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_starts_with?: InputMaybe<Scalars['String']['input']>;
  worker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum ComputeUnit_OrderBy {
  CreatedAt = 'createdAt',
  Deleted = 'deleted',
  Id = 'id',
  Peer = 'peer',
  PeerComputeUnitsInDeal = 'peer__computeUnitsInDeal',
  PeerComputeUnitsTotal = 'peer__computeUnitsTotal',
  PeerCurrentCcCollateralDepositedAt = 'peer__currentCCCollateralDepositedAt',
  PeerCurrentCcEndEpoch = 'peer__currentCCEndEpoch',
  PeerCurrentCcNextCcFailedEpoch = 'peer__currentCCNextCCFailedEpoch',
  PeerDeleted = 'peer__deleted',
  PeerId = 'peer__id',
  PeerIdBase58 = 'peer__idBase58',
  PeerIsAnyJoinedDeals = 'peer__isAnyJoinedDeals',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs',
  Worker = 'worker',
  WorkerDeleted = 'worker__deleted',
  WorkerId = 'worker__id',
  WorkerOffchainWorkerId = 'worker__offchainWorkerId'
}

export type CoordinatorSetTx = {
  __typename?: 'CoordinatorSetTx';
  coordinator: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  tx: Scalars['String']['output'];
};

export type CoordinatorSetTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CoordinatorSetTx_Filter>>>;
  coordinator?: InputMaybe<Scalars['String']['input']>;
  coordinator_contains?: InputMaybe<Scalars['String']['input']>;
  coordinator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_ends_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_gt?: InputMaybe<Scalars['String']['input']>;
  coordinator_gte?: InputMaybe<Scalars['String']['input']>;
  coordinator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinator_lt?: InputMaybe<Scalars['String']['input']>;
  coordinator_lte?: InputMaybe<Scalars['String']['input']>;
  coordinator_not?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_contains?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_starts_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<CoordinatorSetTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum CoordinatorSetTx_OrderBy {
  Coordinator = 'coordinator',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Tx = 'tx'
}

export type CurrentCoordinator = {
  __typename?: 'CurrentCoordinator';
  coordinator: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CurrentCoordinator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CurrentCoordinator_Filter>>>;
  coordinator?: InputMaybe<Scalars['String']['input']>;
  coordinator_contains?: InputMaybe<Scalars['String']['input']>;
  coordinator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_ends_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_gt?: InputMaybe<Scalars['String']['input']>;
  coordinator_gte?: InputMaybe<Scalars['String']['input']>;
  coordinator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinator_lt?: InputMaybe<Scalars['String']['input']>;
  coordinator_lte?: InputMaybe<Scalars['String']['input']>;
  coordinator_not?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_contains?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  coordinator_starts_with?: InputMaybe<Scalars['String']['input']>;
  coordinator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CurrentCoordinator_Filter>>>;
};

export enum CurrentCoordinator_OrderBy {
  Coordinator = 'coordinator',
  Id = 'id'
}

export type Datacenter = {
  __typename?: 'Datacenter';
  certifications?: Maybe<Array<Scalars['String']['output']>>;
  cityCode: Scalars['String']['output'];
  cityIndex: Scalars['BigInt']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  offers?: Maybe<Array<Offer>>;
  peerCount: Scalars['Int']['output'];
  providerCount: Scalars['Int']['output'];
  storage: DatacenterStorage;
  tier: Scalars['BigInt']['output'];
};


export type DatacenterOffersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Offer_Filter>;
};

/** Store all datacenter ids under index '0' */
export type DatacenterStorage = {
  __typename?: 'DatacenterStorage';
  datacenters: Array<Datacenter>;
  id: Scalars['ID']['output'];
};


/** Store all datacenter ids under index '0' */
export type DatacenterStorageDatacentersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Datacenter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Datacenter_Filter>;
};

export type DatacenterStorage_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DatacenterStorage_Filter>>>;
  datacenters_?: InputMaybe<Datacenter_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DatacenterStorage_Filter>>>;
};

export enum DatacenterStorage_OrderBy {
  Datacenters = 'datacenters',
  Id = 'id'
}

export type Datacenter_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Datacenter_Filter>>>;
  certifications?: InputMaybe<Array<Scalars['String']['input']>>;
  certifications_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  certifications_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  certifications_not?: InputMaybe<Array<Scalars['String']['input']>>;
  certifications_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  certifications_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  cityCode?: InputMaybe<Scalars['String']['input']>;
  cityCode_contains?: InputMaybe<Scalars['String']['input']>;
  cityCode_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  cityCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  cityCode_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  cityCode_gt?: InputMaybe<Scalars['String']['input']>;
  cityCode_gte?: InputMaybe<Scalars['String']['input']>;
  cityCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cityCode_lt?: InputMaybe<Scalars['String']['input']>;
  cityCode_lte?: InputMaybe<Scalars['String']['input']>;
  cityCode_not?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cityCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cityCode_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  cityCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  cityCode_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  cityIndex?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cityIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  cityIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  countryCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  countryCode_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  countryCode_gt?: InputMaybe<Scalars['String']['input']>;
  countryCode_gte?: InputMaybe<Scalars['String']['input']>;
  countryCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode_lt?: InputMaybe<Scalars['String']['input']>;
  countryCode_lte?: InputMaybe<Scalars['String']['input']>;
  countryCode_not?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  countryCode_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  countryCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  countryCode_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  offers_?: InputMaybe<Offer_Filter>;
  or?: InputMaybe<Array<InputMaybe<Datacenter_Filter>>>;
  peerCount?: InputMaybe<Scalars['Int']['input']>;
  peerCount_gt?: InputMaybe<Scalars['Int']['input']>;
  peerCount_gte?: InputMaybe<Scalars['Int']['input']>;
  peerCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  peerCount_lt?: InputMaybe<Scalars['Int']['input']>;
  peerCount_lte?: InputMaybe<Scalars['Int']['input']>;
  peerCount_not?: InputMaybe<Scalars['Int']['input']>;
  peerCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  providerCount?: InputMaybe<Scalars['Int']['input']>;
  providerCount_gt?: InputMaybe<Scalars['Int']['input']>;
  providerCount_gte?: InputMaybe<Scalars['Int']['input']>;
  providerCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  providerCount_lt?: InputMaybe<Scalars['Int']['input']>;
  providerCount_lte?: InputMaybe<Scalars['Int']['input']>;
  providerCount_not?: InputMaybe<Scalars['Int']['input']>;
  providerCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  storage?: InputMaybe<Scalars['String']['input']>;
  storage_?: InputMaybe<DatacenterStorage_Filter>;
  storage_contains?: InputMaybe<Scalars['String']['input']>;
  storage_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_ends_with?: InputMaybe<Scalars['String']['input']>;
  storage_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_gt?: InputMaybe<Scalars['String']['input']>;
  storage_gte?: InputMaybe<Scalars['String']['input']>;
  storage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storage_lt?: InputMaybe<Scalars['String']['input']>;
  storage_lte?: InputMaybe<Scalars['String']['input']>;
  storage_not?: InputMaybe<Scalars['String']['input']>;
  storage_not_contains?: InputMaybe<Scalars['String']['input']>;
  storage_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  storage_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  storage_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  storage_starts_with?: InputMaybe<Scalars['String']['input']>;
  storage_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['BigInt']['input']>;
  tier_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tier_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tier_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tier_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tier_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tier_not?: InputMaybe<Scalars['BigInt']['input']>;
  tier_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum Datacenter_OrderBy {
  Certifications = 'certifications',
  CityCode = 'cityCode',
  CityIndex = 'cityIndex',
  CountryCode = 'countryCode',
  Id = 'id',
  Offers = 'offers',
  PeerCount = 'peerCount',
  ProviderCount = 'providerCount',
  Storage = 'storage',
  StorageId = 'storage__id',
  Tier = 'tier'
}

export type Deal = {
  __typename?: 'Deal';
  appCID: Scalars['String']['output'];
  createdAt: Scalars['BigInt']['output'];
  cuCountPerWorker: Scalars['Int']['output'];
  depositedSum: Scalars['BigInt']['output'];
  effectors?: Maybe<Array<DealToEffector>>;
  id: Scalars['ID']['output'];
  /** Many to many to access joined peers to maintain protocol restrictions */
  joinedPeers?: Maybe<Array<DealToPeer>>;
  joinedWorkers?: Maybe<Array<Worker>>;
  /** The last matched timestamp. */
  matchedAt?: Maybe<Scalars['BigInt']['output']>;
  /** Currently matched workers == matched compute units. */
  matchedWorkersCurrentCount: Scalars['Int']['output'];
  maxPaidEpoch?: Maybe<Scalars['BigInt']['output']>;
  maxWorkersPerProvider: Scalars['Int']['output'];
  minWorkers: Scalars['Int']['output'];
  owner: Scalars['String']['output'];
  paymentToken: Token;
  pricePerCuPerEpoch: Scalars['BigInt']['output'];
  pricePerEpoch?: Maybe<Scalars['BigInt']['output']>;
  providersAccessList?: Maybe<Array<DealToProvidersAccess>>;
  /** It represents AccessType of Deal contract. */
  providersAccessType: Scalars['Int']['output'];
  registeredWorkersCurrentCount: Scalars['Int']['output'];
  resourceRequirements?: Maybe<Array<ResourceRequirements>>;
  targetWorkers: Scalars['Int']['output'];
  version?: Maybe<Scalars['BigInt']['output']>;
  withdrawalSum: Scalars['BigInt']['output'];
};


export type DealEffectorsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealToEffector_Filter>;
};


export type DealJoinedPeersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToPeer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealToPeer_Filter>;
};


export type DealJoinedWorkersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Worker_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Worker_Filter>;
};


export type DealProvidersAccessListArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToProvidersAccess_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealToProvidersAccess_Filter>;
};


export type DealResourceRequirementsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceRequirements_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceRequirements_Filter>;
};

export type DealBalanceTx = {
  __typename?: 'DealBalanceTx';
  amount: Scalars['BigInt']['output'];
  deal: Deal;
  id: Scalars['ID']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  user: Scalars['String']['output'];
};

export type DealBalanceTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<DealBalanceTx_Filter>>>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DealBalanceTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  type_gt?: InputMaybe<Scalars['Int']['input']>;
  type_gte?: InputMaybe<Scalars['Int']['input']>;
  type_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  type_lt?: InputMaybe<Scalars['Int']['input']>;
  type_lte?: InputMaybe<Scalars['Int']['input']>;
  type_not?: InputMaybe<Scalars['Int']['input']>;
  type_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum DealBalanceTx_OrderBy {
  Amount = 'amount',
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Id = 'id',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx',
  Type = 'type',
  User = 'user'
}

export type DealToEffector = {
  __typename?: 'DealToEffector';
  deal: Deal;
  effector: Effector;
  id: Scalars['ID']['output'];
};

export type DealToEffector_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DealToEffector_Filter>>>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector?: InputMaybe<Scalars['String']['input']>;
  effector_?: InputMaybe<Effector_Filter>;
  effector_contains?: InputMaybe<Scalars['String']['input']>;
  effector_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_ends_with?: InputMaybe<Scalars['String']['input']>;
  effector_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_gt?: InputMaybe<Scalars['String']['input']>;
  effector_gte?: InputMaybe<Scalars['String']['input']>;
  effector_in?: InputMaybe<Array<Scalars['String']['input']>>;
  effector_lt?: InputMaybe<Scalars['String']['input']>;
  effector_lte?: InputMaybe<Scalars['String']['input']>;
  effector_not?: InputMaybe<Scalars['String']['input']>;
  effector_not_contains?: InputMaybe<Scalars['String']['input']>;
  effector_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  effector_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  effector_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  effector_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_starts_with?: InputMaybe<Scalars['String']['input']>;
  effector_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DealToEffector_Filter>>>;
};

export enum DealToEffector_OrderBy {
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Effector = 'effector',
  EffectorDescription = 'effector__description',
  EffectorId = 'effector__id',
  Id = 'id'
}

export type DealToPeer = {
  __typename?: 'DealToPeer';
  /** Helper field to understand number of connections. */
  connections: Scalars['Int']['output'];
  deal: Deal;
  id: Scalars['ID']['output'];
  peer: Peer;
};

export type DealToPeer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DealToPeer_Filter>>>;
  connections?: InputMaybe<Scalars['Int']['input']>;
  connections_gt?: InputMaybe<Scalars['Int']['input']>;
  connections_gte?: InputMaybe<Scalars['Int']['input']>;
  connections_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  connections_lt?: InputMaybe<Scalars['Int']['input']>;
  connections_lte?: InputMaybe<Scalars['Int']['input']>;
  connections_not?: InputMaybe<Scalars['Int']['input']>;
  connections_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DealToPeer_Filter>>>;
  peer?: InputMaybe<Scalars['String']['input']>;
  peer_?: InputMaybe<Peer_Filter>;
  peer_contains?: InputMaybe<Scalars['String']['input']>;
  peer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_gt?: InputMaybe<Scalars['String']['input']>;
  peer_gte?: InputMaybe<Scalars['String']['input']>;
  peer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_lt?: InputMaybe<Scalars['String']['input']>;
  peer_lte?: InputMaybe<Scalars['String']['input']>;
  peer_not?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum DealToPeer_OrderBy {
  Connections = 'connections',
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Id = 'id',
  Peer = 'peer',
  PeerComputeUnitsInDeal = 'peer__computeUnitsInDeal',
  PeerComputeUnitsTotal = 'peer__computeUnitsTotal',
  PeerCurrentCcCollateralDepositedAt = 'peer__currentCCCollateralDepositedAt',
  PeerCurrentCcEndEpoch = 'peer__currentCCEndEpoch',
  PeerCurrentCcNextCcFailedEpoch = 'peer__currentCCNextCCFailedEpoch',
  PeerDeleted = 'peer__deleted',
  PeerId = 'peer__id',
  PeerIdBase58 = 'peer__idBase58',
  PeerIsAnyJoinedDeals = 'peer__isAnyJoinedDeals'
}

/** It represents m2m b/w deal and provider in context of access list. */
export type DealToProvidersAccess = {
  __typename?: 'DealToProvidersAccess';
  deal: Deal;
  id: Scalars['ID']['output'];
  provider: Provider;
};

export type DealToProvidersAccess_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DealToProvidersAccess_Filter>>>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DealToProvidersAccess_Filter>>>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum DealToProvidersAccess_OrderBy {
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Id = 'id',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs'
}

export type Deal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Deal_Filter>>>;
  appCID?: InputMaybe<Scalars['String']['input']>;
  appCID_contains?: InputMaybe<Scalars['String']['input']>;
  appCID_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  appCID_ends_with?: InputMaybe<Scalars['String']['input']>;
  appCID_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  appCID_gt?: InputMaybe<Scalars['String']['input']>;
  appCID_gte?: InputMaybe<Scalars['String']['input']>;
  appCID_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appCID_lt?: InputMaybe<Scalars['String']['input']>;
  appCID_lte?: InputMaybe<Scalars['String']['input']>;
  appCID_not?: InputMaybe<Scalars['String']['input']>;
  appCID_not_contains?: InputMaybe<Scalars['String']['input']>;
  appCID_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  appCID_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appCID_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  appCID_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appCID_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appCID_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  appCID_starts_with?: InputMaybe<Scalars['String']['input']>;
  appCID_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cuCountPerWorker?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_gt?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_gte?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  cuCountPerWorker_lt?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_lte?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_not?: InputMaybe<Scalars['Int']['input']>;
  cuCountPerWorker_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  depositedSum?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_gt?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_gte?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  depositedSum_lt?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_lte?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_not?: InputMaybe<Scalars['BigInt']['input']>;
  depositedSum_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  effectors_?: InputMaybe<DealToEffector_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  joinedPeers_?: InputMaybe<DealToPeer_Filter>;
  joinedWorkers_?: InputMaybe<Worker_Filter>;
  matchedAt?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  matchedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  matchedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  matchedWorkersCurrentCount?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_gt?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_gte?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  matchedWorkersCurrentCount_lt?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_lte?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_not?: InputMaybe<Scalars['Int']['input']>;
  matchedWorkersCurrentCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxPaidEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxPaidEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxPaidEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxWorkersPerProvider?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_gt?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_gte?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxWorkersPerProvider_lt?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_lte?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_not?: InputMaybe<Scalars['Int']['input']>;
  maxWorkersPerProvider_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minWorkers?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_gt?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_gte?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minWorkers_lt?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_lte?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_not?: InputMaybe<Scalars['Int']['input']>;
  minWorkers_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Deal_Filter>>>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  paymentToken_?: InputMaybe<Token_Filter>;
  paymentToken_contains?: InputMaybe<Scalars['String']['input']>;
  paymentToken_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_gt?: InputMaybe<Scalars['String']['input']>;
  paymentToken_gte?: InputMaybe<Scalars['String']['input']>;
  paymentToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentToken_lt?: InputMaybe<Scalars['String']['input']>;
  paymentToken_lte?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pricePerCuPerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pricePerCuPerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerCuPerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pricePerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pricePerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  providersAccessList_?: InputMaybe<DealToProvidersAccess_Filter>;
  providersAccessType?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_gt?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_gte?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  providersAccessType_lt?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_lte?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_not?: InputMaybe<Scalars['Int']['input']>;
  providersAccessType_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  registeredWorkersCurrentCount?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_gt?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_gte?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  registeredWorkersCurrentCount_lt?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_lte?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_not?: InputMaybe<Scalars['Int']['input']>;
  registeredWorkersCurrentCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  resourceRequirements_?: InputMaybe<ResourceRequirements_Filter>;
  targetWorkers?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_gt?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_gte?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  targetWorkers_lt?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_lte?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_not?: InputMaybe<Scalars['Int']['input']>;
  targetWorkers_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  version?: InputMaybe<Scalars['BigInt']['input']>;
  version_gt?: InputMaybe<Scalars['BigInt']['input']>;
  version_gte?: InputMaybe<Scalars['BigInt']['input']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  version_lt?: InputMaybe<Scalars['BigInt']['input']>;
  version_lte?: InputMaybe<Scalars['BigInt']['input']>;
  version_not?: InputMaybe<Scalars['BigInt']['input']>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawalSum?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawalSum_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawalSum_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum Deal_OrderBy {
  AppCid = 'appCID',
  CreatedAt = 'createdAt',
  CuCountPerWorker = 'cuCountPerWorker',
  DepositedSum = 'depositedSum',
  Effectors = 'effectors',
  Id = 'id',
  JoinedPeers = 'joinedPeers',
  JoinedWorkers = 'joinedWorkers',
  MatchedAt = 'matchedAt',
  MatchedWorkersCurrentCount = 'matchedWorkersCurrentCount',
  MaxPaidEpoch = 'maxPaidEpoch',
  MaxWorkersPerProvider = 'maxWorkersPerProvider',
  MinWorkers = 'minWorkers',
  Owner = 'owner',
  PaymentToken = 'paymentToken',
  PaymentTokenDecimals = 'paymentToken__decimals',
  PaymentTokenId = 'paymentToken__id',
  PaymentTokenSymbol = 'paymentToken__symbol',
  PricePerCuPerEpoch = 'pricePerCuPerEpoch',
  PricePerEpoch = 'pricePerEpoch',
  ProvidersAccessList = 'providersAccessList',
  ProvidersAccessType = 'providersAccessType',
  RegisteredWorkersCurrentCount = 'registeredWorkersCurrentCount',
  ResourceRequirements = 'resourceRequirements',
  TargetWorkers = 'targetWorkers',
  Version = 'version',
  WithdrawalSum = 'withdrawalSum'
}

/** Effector table is obsolete table since it is a simple mapping. */
export type Effector = {
  __typename?: 'Effector';
  description: Scalars['String']['output'];
  /** id and CID are the same. */
  id: Scalars['ID']['output'];
  offers?: Maybe<Array<OfferToEffector>>;
};


/** Effector table is obsolete table since it is a simple mapping. */
export type EffectorOffersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OfferToEffector_Filter>;
};

export type Effector_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Effector_Filter>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  offers_?: InputMaybe<OfferToEffector_Filter>;
  or?: InputMaybe<Array<InputMaybe<Effector_Filter>>>;
};

export enum Effector_OrderBy {
  Description = 'description',
  Id = 'id',
  Offers = 'offers'
}

/** This model is designed to store epoch related information. Note, that in other models it is more efficient to store epoch as bigint rather than relation to that model (with relation you could complicate your queries by epoch, or you will need to store additional epoch field anyway). */
export type EpochStatistic = {
  __typename?: 'EpochStatistic';
  /** Epoch number. Note, that for current epoch right boarder is approximate. */
  id: Scalars['ID']['output'];
  startBlock: Scalars['BigInt']['output'];
  startTimestamp: Scalars['BigInt']['output'];
};

export type EpochStatistic_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<EpochStatistic_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<EpochStatistic_Filter>>>;
  startBlock?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startBlock_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_not?: InputMaybe<Scalars['BigInt']['input']>;
  startBlock_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum EpochStatistic_OrderBy {
  Id = 'id',
  StartBlock = 'startBlock',
  StartTimestamp = 'startTimestamp'
}

export type FreeTotalBalance = {
  __typename?: 'FreeTotalBalance';
  balance: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type FreeTotalBalance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FreeTotalBalance_Filter>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<FreeTotalBalance_Filter>>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum FreeTotalBalance_OrderBy {
  Balance = 'balance',
  Id = 'id',
  Token = 'token'
}

export type FreezeHistoryTx = {
  __typename?: 'FreezeHistoryTx';
  action: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  tx: Scalars['String']['output'];
  user: User;
};

export type FreezeHistoryTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  action?: InputMaybe<Scalars['Int']['input']>;
  action_gt?: InputMaybe<Scalars['Int']['input']>;
  action_gte?: InputMaybe<Scalars['Int']['input']>;
  action_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  action_lt?: InputMaybe<Scalars['Int']['input']>;
  action_lte?: InputMaybe<Scalars['Int']['input']>;
  action_not?: InputMaybe<Scalars['Int']['input']>;
  action_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  and?: InputMaybe<Array<InputMaybe<FreezeHistoryTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<FreezeHistoryTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_?: InputMaybe<User_Filter>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum FreezeHistoryTx_OrderBy {
  Action = 'action',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Tx = 'tx',
  User = 'user',
  UserId = 'user__id',
  UserStatus = 'user__status'
}

export type FundedFromLimboTx = {
  __typename?: 'FundedFromLimboTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export type FundedFromLimboTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<FundedFromLimboTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<FundedFromLimboTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum FundedFromLimboTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx',
  User = 'user'
}

/**
 * In the scheme below we used behaviour where deleted entity marked as deleted=True instead of actual deletion (in contrast as in contract).
 * Thus, please refer to the deleted field when querying the data.
 * Note, all deleted entities are out of scope for presented counters in all models.
 *
 */
export type GraphNetwork = {
  __typename?: 'GraphNetwork';
  approvedProviders: Scalars['BigInt']['output'];
  capacityCommitmentsTotal: Scalars['BigInt']['output'];
  capacityMaxFailedRatio?: Maybe<Scalars['Int']['output']>;
  coreEpochDuration?: Maybe<Scalars['Int']['output']>;
  coreMinDealRematchingEpochs?: Maybe<Scalars['Int']['output']>;
  corePrecision?: Maybe<Scalars['BigInt']['output']>;
  dealsTotal: Scalars['BigInt']['output'];
  diamondContractAddress?: Maybe<Scalars['String']['output']>;
  difficulty?: Maybe<Scalars['String']['output']>;
  effectorsTotal: Scalars['BigInt']['output'];
  fltPrice: Scalars['BigInt']['output'];
  /** ID is set to 1 */
  id: Scalars['ID']['output'];
  initTimestamp?: Maybe<Scalars['Int']['output']>;
  lastUpdateTimestamp: Scalars['BigInt']['output'];
  maxProofsPerEpoch?: Maybe<Scalars['BigInt']['output']>;
  maxRewardPerEpoch?: Maybe<Scalars['BigInt']['output']>;
  minRequiredProofsPerEpoch?: Maybe<Scalars['Int']['output']>;
  minRewardPerEpoch?: Maybe<Scalars['BigInt']['output']>;
  networkName: Scalars['String']['output'];
  offersTotal: Scalars['BigInt']['output'];
  peersTotal: Scalars['BigInt']['output'];
  proofsTotal: Scalars['BigInt']['output'];
  /** Providers that register themselves in the network with setInfo() method. */
  providersRegisteredTotal: Scalars['BigInt']['output'];
  slashingRate?: Maybe<Scalars['BigInt']['output']>;
  tokensTotal: Scalars['BigInt']['output'];
  totalCollateral: Scalars['BigInt']['output'];
  usdCollateralPerUnit?: Maybe<Scalars['BigInt']['output']>;
  usdTargetRevenuePerEpoch: Scalars['BigInt']['output'];
  vestingPeriodCount?: Maybe<Scalars['BigInt']['output']>;
  vestingPeriodDuration?: Maybe<Scalars['BigInt']['output']>;
  withdrawEpochsAfterFailed?: Maybe<Scalars['BigInt']['output']>;
};

export type GraphNetwork_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GraphNetwork_Filter>>>;
  approvedProviders?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_gt?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_gte?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  approvedProviders_lt?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_lte?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_not?: InputMaybe<Scalars['BigInt']['input']>;
  approvedProviders_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  capacityCommitmentsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  capacityCommitmentsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  capacityCommitmentsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  capacityMaxFailedRatio?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_gt?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_gte?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  capacityMaxFailedRatio_lt?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_lte?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_not?: InputMaybe<Scalars['Int']['input']>;
  capacityMaxFailedRatio_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  coreEpochDuration?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_gt?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_gte?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  coreEpochDuration_lt?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_lte?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_not?: InputMaybe<Scalars['Int']['input']>;
  coreEpochDuration_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  coreMinDealRematchingEpochs?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_gt?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_gte?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  coreMinDealRematchingEpochs_lt?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_lte?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_not?: InputMaybe<Scalars['Int']['input']>;
  coreMinDealRematchingEpochs_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  corePrecision?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_gt?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_gte?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  corePrecision_lt?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_lte?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_not?: InputMaybe<Scalars['BigInt']['input']>;
  corePrecision_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dealsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  dealsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  diamondContractAddress?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_contains?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_ends_with?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_gt?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_gte?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_in?: InputMaybe<Array<Scalars['String']['input']>>;
  diamondContractAddress_lt?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_lte?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_contains?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  diamondContractAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_starts_with?: InputMaybe<Scalars['String']['input']>;
  diamondContractAddress_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<Scalars['String']['input']>;
  difficulty_contains?: InputMaybe<Scalars['String']['input']>;
  difficulty_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty_ends_with?: InputMaybe<Scalars['String']['input']>;
  difficulty_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty_gt?: InputMaybe<Scalars['String']['input']>;
  difficulty_gte?: InputMaybe<Scalars['String']['input']>;
  difficulty_in?: InputMaybe<Array<Scalars['String']['input']>>;
  difficulty_lt?: InputMaybe<Scalars['String']['input']>;
  difficulty_lte?: InputMaybe<Scalars['String']['input']>;
  difficulty_not?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_contains?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  difficulty_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  difficulty_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  difficulty_starts_with?: InputMaybe<Scalars['String']['input']>;
  difficulty_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effectorsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  effectorsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  effectorsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fltPrice?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fltPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  fltPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initTimestamp?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_gt?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_gte?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  initTimestamp_lt?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_lte?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_not?: InputMaybe<Scalars['Int']['input']>;
  initTimestamp_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastUpdateTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lastUpdateTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdateTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxProofsPerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxProofsPerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxProofsPerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxRewardPerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxRewardPerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxRewardPerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minRequiredProofsPerEpoch?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_gt?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_gte?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRequiredProofsPerEpoch_lt?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_lte?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_not?: InputMaybe<Scalars['Int']['input']>;
  minRequiredProofsPerEpoch_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRewardPerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minRewardPerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  minRewardPerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  networkName?: InputMaybe<Scalars['String']['input']>;
  networkName_contains?: InputMaybe<Scalars['String']['input']>;
  networkName_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  networkName_ends_with?: InputMaybe<Scalars['String']['input']>;
  networkName_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  networkName_gt?: InputMaybe<Scalars['String']['input']>;
  networkName_gte?: InputMaybe<Scalars['String']['input']>;
  networkName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  networkName_lt?: InputMaybe<Scalars['String']['input']>;
  networkName_lte?: InputMaybe<Scalars['String']['input']>;
  networkName_not?: InputMaybe<Scalars['String']['input']>;
  networkName_not_contains?: InputMaybe<Scalars['String']['input']>;
  networkName_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  networkName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  networkName_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  networkName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  networkName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  networkName_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  networkName_starts_with?: InputMaybe<Scalars['String']['input']>;
  networkName_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offersTotal?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  offersTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  offersTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  or?: InputMaybe<Array<InputMaybe<GraphNetwork_Filter>>>;
  peersTotal?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  peersTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  peersTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  proofsTotal?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  proofsTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  proofsTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  providersRegisteredTotal?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  providersRegisteredTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  providersRegisteredTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  slashingRate?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  slashingRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  slashingRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokensTotal?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokensTotal_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_not?: InputMaybe<Scalars['BigInt']['input']>;
  tokensTotal_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  usdCollateralPerUnit?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  usdCollateralPerUnit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_not?: InputMaybe<Scalars['BigInt']['input']>;
  usdCollateralPerUnit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  usdTargetRevenuePerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  usdTargetRevenuePerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  usdTargetRevenuePerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  vestingPeriodCount?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  vestingPeriodCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodCount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  vestingPeriodDuration?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  vestingPeriodDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  vestingPeriodDuration_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawEpochsAfterFailed?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawEpochsAfterFailed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawEpochsAfterFailed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum GraphNetwork_OrderBy {
  ApprovedProviders = 'approvedProviders',
  CapacityCommitmentsTotal = 'capacityCommitmentsTotal',
  CapacityMaxFailedRatio = 'capacityMaxFailedRatio',
  CoreEpochDuration = 'coreEpochDuration',
  CoreMinDealRematchingEpochs = 'coreMinDealRematchingEpochs',
  CorePrecision = 'corePrecision',
  DealsTotal = 'dealsTotal',
  DiamondContractAddress = 'diamondContractAddress',
  Difficulty = 'difficulty',
  EffectorsTotal = 'effectorsTotal',
  FltPrice = 'fltPrice',
  Id = 'id',
  InitTimestamp = 'initTimestamp',
  LastUpdateTimestamp = 'lastUpdateTimestamp',
  MaxProofsPerEpoch = 'maxProofsPerEpoch',
  MaxRewardPerEpoch = 'maxRewardPerEpoch',
  MinRequiredProofsPerEpoch = 'minRequiredProofsPerEpoch',
  MinRewardPerEpoch = 'minRewardPerEpoch',
  NetworkName = 'networkName',
  OffersTotal = 'offersTotal',
  PeersTotal = 'peersTotal',
  ProofsTotal = 'proofsTotal',
  ProvidersRegisteredTotal = 'providersRegisteredTotal',
  SlashingRate = 'slashingRate',
  TokensTotal = 'tokensTotal',
  TotalCollateral = 'totalCollateral',
  UsdCollateralPerUnit = 'usdCollateralPerUnit',
  UsdTargetRevenuePerEpoch = 'usdTargetRevenuePerEpoch',
  VestingPeriodCount = 'vestingPeriodCount',
  VestingPeriodDuration = 'vestingPeriodDuration',
  WithdrawEpochsAfterFailed = 'withdrawEpochsAfterFailed'
}

export type LimboTotalBalance = {
  __typename?: 'LimboTotalBalance';
  balance: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type LimboTotalBalance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<LimboTotalBalance_Filter>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<LimboTotalBalance_Filter>>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum LimboTotalBalance_OrderBy {
  Balance = 'balance',
  Id = 'id',
  Token = 'token'
}

export type Offer = {
  __typename?: 'Offer';
  /** It depends on if CU in deal or not. */
  computeUnitsAvailable?: Maybe<Scalars['Int']['output']>;
  computeUnitsTotal?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['BigInt']['output'];
  datacenter?: Maybe<Datacenter>;
  deleted: Scalars['Boolean']['output'];
  effectors?: Maybe<Array<OfferToEffector>>;
  /**
   * Used in the next figma views:
   * - Offer from List Of offers 1.2.
   * - Offer 1.2
   *
   */
  id: Scalars['ID']['output'];
  maxProtocolVersion?: Maybe<Scalars['BigInt']['output']>;
  minProtocolVersion?: Maybe<Scalars['BigInt']['output']>;
  offerResources?: Maybe<Array<OfferResource>>;
  paymentToken: Token;
  peers?: Maybe<Array<Peer>>;
  pricePerEpoch: Scalars['BigInt']['output'];
  provider: Provider;
  updatedAt: Scalars['BigInt']['output'];
};


export type OfferEffectorsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OfferToEffector_Filter>;
};


export type OfferOfferResourcesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OfferResource_Filter>;
};


export type OfferPeersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Peer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Peer_Filter>;
};

export type OfferResource = {
  __typename?: 'OfferResource';
  id: Scalars['ID']['output'];
  offer: Offer;
  price: Scalars['BigInt']['output'];
  resourceDescription: ResourceDescription;
};

export type OfferResource_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OfferResource_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  offer?: InputMaybe<Scalars['String']['input']>;
  offer_?: InputMaybe<Offer_Filter>;
  offer_contains?: InputMaybe<Scalars['String']['input']>;
  offer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_gt?: InputMaybe<Scalars['String']['input']>;
  offer_gte?: InputMaybe<Scalars['String']['input']>;
  offer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_lt?: InputMaybe<Scalars['String']['input']>;
  offer_lte?: InputMaybe<Scalars['String']['input']>;
  offer_not?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<OfferResource_Filter>>>;
  price?: InputMaybe<Scalars['BigInt']['input']>;
  price_gt?: InputMaybe<Scalars['BigInt']['input']>;
  price_gte?: InputMaybe<Scalars['BigInt']['input']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  price_lt?: InputMaybe<Scalars['BigInt']['input']>;
  price_lte?: InputMaybe<Scalars['BigInt']['input']>;
  price_not?: InputMaybe<Scalars['BigInt']['input']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  resourceDescription?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_?: InputMaybe<ResourceDescription_Filter>;
  resourceDescription_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_lt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_lte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum OfferResource_OrderBy {
  Id = 'id',
  Offer = 'offer',
  OfferComputeUnitsAvailable = 'offer__computeUnitsAvailable',
  OfferComputeUnitsTotal = 'offer__computeUnitsTotal',
  OfferCreatedAt = 'offer__createdAt',
  OfferDeleted = 'offer__deleted',
  OfferId = 'offer__id',
  OfferMaxProtocolVersion = 'offer__maxProtocolVersion',
  OfferMinProtocolVersion = 'offer__minProtocolVersion',
  OfferPricePerEpoch = 'offer__pricePerEpoch',
  OfferUpdatedAt = 'offer__updatedAt',
  Price = 'price',
  ResourceDescription = 'resourceDescription',
  ResourceDescriptionCreatedAt = 'resourceDescription__createdAt',
  ResourceDescriptionId = 'resourceDescription__id',
  ResourceDescriptionMetadata = 'resourceDescription__metadata',
  ResourceDescriptionType = 'resourceDescription__type',
  ResourceDescriptionUpdatedAt = 'resourceDescription__updatedAt'
}

/**
 * To support many2many b/w offer and effector.
 * E.g. to use
 * {
 *   offers {
 *     effectors {
 *       effector {
 *         description
 *       }
 *     }
 *   }
 * }
 *
 */
export type OfferToEffector = {
  __typename?: 'OfferToEffector';
  effector: Effector;
  id: Scalars['ID']['output'];
  offer: Offer;
};

export type OfferToEffector_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OfferToEffector_Filter>>>;
  effector?: InputMaybe<Scalars['String']['input']>;
  effector_?: InputMaybe<Effector_Filter>;
  effector_contains?: InputMaybe<Scalars['String']['input']>;
  effector_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_ends_with?: InputMaybe<Scalars['String']['input']>;
  effector_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_gt?: InputMaybe<Scalars['String']['input']>;
  effector_gte?: InputMaybe<Scalars['String']['input']>;
  effector_in?: InputMaybe<Array<Scalars['String']['input']>>;
  effector_lt?: InputMaybe<Scalars['String']['input']>;
  effector_lte?: InputMaybe<Scalars['String']['input']>;
  effector_not?: InputMaybe<Scalars['String']['input']>;
  effector_not_contains?: InputMaybe<Scalars['String']['input']>;
  effector_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  effector_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  effector_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  effector_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  effector_starts_with?: InputMaybe<Scalars['String']['input']>;
  effector_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  offer?: InputMaybe<Scalars['String']['input']>;
  offer_?: InputMaybe<Offer_Filter>;
  offer_contains?: InputMaybe<Scalars['String']['input']>;
  offer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_gt?: InputMaybe<Scalars['String']['input']>;
  offer_gte?: InputMaybe<Scalars['String']['input']>;
  offer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_lt?: InputMaybe<Scalars['String']['input']>;
  offer_lte?: InputMaybe<Scalars['String']['input']>;
  offer_not?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<OfferToEffector_Filter>>>;
};

export enum OfferToEffector_OrderBy {
  Effector = 'effector',
  EffectorDescription = 'effector__description',
  EffectorId = 'effector__id',
  Id = 'id',
  Offer = 'offer',
  OfferComputeUnitsAvailable = 'offer__computeUnitsAvailable',
  OfferComputeUnitsTotal = 'offer__computeUnitsTotal',
  OfferCreatedAt = 'offer__createdAt',
  OfferDeleted = 'offer__deleted',
  OfferId = 'offer__id',
  OfferMaxProtocolVersion = 'offer__maxProtocolVersion',
  OfferMinProtocolVersion = 'offer__minProtocolVersion',
  OfferPricePerEpoch = 'offer__pricePerEpoch',
  OfferUpdatedAt = 'offer__updatedAt'
}

export type Offer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Offer_Filter>>>;
  computeUnitsAvailable?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsAvailable_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsAvailable_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  datacenter?: InputMaybe<Scalars['String']['input']>;
  datacenter_?: InputMaybe<Datacenter_Filter>;
  datacenter_contains?: InputMaybe<Scalars['String']['input']>;
  datacenter_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  datacenter_ends_with?: InputMaybe<Scalars['String']['input']>;
  datacenter_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  datacenter_gt?: InputMaybe<Scalars['String']['input']>;
  datacenter_gte?: InputMaybe<Scalars['String']['input']>;
  datacenter_in?: InputMaybe<Array<Scalars['String']['input']>>;
  datacenter_lt?: InputMaybe<Scalars['String']['input']>;
  datacenter_lte?: InputMaybe<Scalars['String']['input']>;
  datacenter_not?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_contains?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  datacenter_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  datacenter_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  datacenter_starts_with?: InputMaybe<Scalars['String']['input']>;
  datacenter_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  deleted_not?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  effectors_?: InputMaybe<OfferToEffector_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxProtocolVersion?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxProtocolVersion_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxProtocolVersion_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minProtocolVersion?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minProtocolVersion_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_not?: InputMaybe<Scalars['BigInt']['input']>;
  minProtocolVersion_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  offerResources_?: InputMaybe<OfferResource_Filter>;
  or?: InputMaybe<Array<InputMaybe<Offer_Filter>>>;
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  paymentToken_?: InputMaybe<Token_Filter>;
  paymentToken_contains?: InputMaybe<Scalars['String']['input']>;
  paymentToken_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_gt?: InputMaybe<Scalars['String']['input']>;
  paymentToken_gte?: InputMaybe<Scalars['String']['input']>;
  paymentToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentToken_lt?: InputMaybe<Scalars['String']['input']>;
  paymentToken_lte?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  paymentToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentToken_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peers_?: InputMaybe<Peer_Filter>;
  pricePerEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pricePerEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  pricePerEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum Offer_OrderBy {
  ComputeUnitsAvailable = 'computeUnitsAvailable',
  ComputeUnitsTotal = 'computeUnitsTotal',
  CreatedAt = 'createdAt',
  Datacenter = 'datacenter',
  DatacenterCityCode = 'datacenter__cityCode',
  DatacenterCityIndex = 'datacenter__cityIndex',
  DatacenterCountryCode = 'datacenter__countryCode',
  DatacenterId = 'datacenter__id',
  DatacenterPeerCount = 'datacenter__peerCount',
  DatacenterProviderCount = 'datacenter__providerCount',
  DatacenterTier = 'datacenter__tier',
  Deleted = 'deleted',
  Effectors = 'effectors',
  Id = 'id',
  MaxProtocolVersion = 'maxProtocolVersion',
  MinProtocolVersion = 'minProtocolVersion',
  OfferResources = 'offerResources',
  PaymentToken = 'paymentToken',
  PaymentTokenDecimals = 'paymentToken__decimals',
  PaymentTokenId = 'paymentToken__id',
  PaymentTokenSymbol = 'paymentToken__symbol',
  Peers = 'peers',
  PricePerEpoch = 'pricePerEpoch',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs',
  UpdatedAt = 'updatedAt'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Peer = {
  __typename?: 'Peer';
  /** To access history of capacity commitments. */
  capacityCommitments?: Maybe<Array<CapacityCommitment>>;
  computeUnits?: Maybe<Array<ComputeUnit>>;
  /** Compute units in any deals: update only when moved to deal or out. */
  computeUnitsInDeal: Scalars['Int']['output'];
  computeUnitsTotal: Scalars['Int']['output'];
  /** To understand if collateral for peer have been submitted. This field should be use in conjunction with currentCapacityCommitment. */
  currentCCCollateralDepositedAt?: Maybe<Scalars['BigInt']['output']>;
  currentCCEndEpoch?: Maybe<Scalars['BigInt']['output']>;
  currentCCNextCCFailedEpoch?: Maybe<Scalars['BigInt']['output']>;
  currentCapacityCommitment?: Maybe<CapacityCommitment>;
  deleted: Scalars['Boolean']['output'];
  /** ref to peerId in contract. */
  id: Scalars['ID']['output'];
  idBase58: Scalars['String']['output'];
  isAnyJoinedDeals: Scalars['Boolean']['output'];
  joinedDeals?: Maybe<Array<DealToPeer>>;
  offer: Offer;
  peerResources?: Maybe<Array<PeerResource>>;
  provider: Provider;
};


export type PeerCapacityCommitmentsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitment_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CapacityCommitment_Filter>;
};


export type PeerComputeUnitsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComputeUnit_Filter>;
};


export type PeerJoinedDealsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToPeer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealToPeer_Filter>;
};


export type PeerPeerResourcesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PeerResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PeerResource_Filter>;
};

export type PeerResource = {
  __typename?: 'PeerResource';
  availableSupply: Scalars['BigInt']['output'];
  details: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxSupply: Scalars['BigInt']['output'];
  peer: Peer;
  resourceDescription: ResourceDescription;
  workers?: Maybe<Array<WorkerToResource>>;
};


export type PeerResourceWorkersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkerToResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkerToResource_Filter>;
};

export type PeerResource_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PeerResource_Filter>>>;
  availableSupply?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_gt?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_gte?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  availableSupply_lt?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_lte?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_not?: InputMaybe<Scalars['BigInt']['input']>;
  availableSupply_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  details?: InputMaybe<Scalars['String']['input']>;
  details_contains?: InputMaybe<Scalars['String']['input']>;
  details_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  details_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  details_gt?: InputMaybe<Scalars['String']['input']>;
  details_gte?: InputMaybe<Scalars['String']['input']>;
  details_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_lt?: InputMaybe<Scalars['String']['input']>;
  details_lte?: InputMaybe<Scalars['String']['input']>;
  details_not?: InputMaybe<Scalars['String']['input']>;
  details_not_contains?: InputMaybe<Scalars['String']['input']>;
  details_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  details_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  details_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxSupply?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxSupply_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxSupply_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  or?: InputMaybe<Array<InputMaybe<PeerResource_Filter>>>;
  peer?: InputMaybe<Scalars['String']['input']>;
  peer_?: InputMaybe<Peer_Filter>;
  peer_contains?: InputMaybe<Scalars['String']['input']>;
  peer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_gt?: InputMaybe<Scalars['String']['input']>;
  peer_gte?: InputMaybe<Scalars['String']['input']>;
  peer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_lt?: InputMaybe<Scalars['String']['input']>;
  peer_lte?: InputMaybe<Scalars['String']['input']>;
  peer_not?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_?: InputMaybe<ResourceDescription_Filter>;
  resourceDescription_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_lt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_lte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  workers_?: InputMaybe<WorkerToResource_Filter>;
};

export enum PeerResource_OrderBy {
  AvailableSupply = 'availableSupply',
  Details = 'details',
  Id = 'id',
  MaxSupply = 'maxSupply',
  Peer = 'peer',
  PeerComputeUnitsInDeal = 'peer__computeUnitsInDeal',
  PeerComputeUnitsTotal = 'peer__computeUnitsTotal',
  PeerCurrentCcCollateralDepositedAt = 'peer__currentCCCollateralDepositedAt',
  PeerCurrentCcEndEpoch = 'peer__currentCCEndEpoch',
  PeerCurrentCcNextCcFailedEpoch = 'peer__currentCCNextCCFailedEpoch',
  PeerDeleted = 'peer__deleted',
  PeerId = 'peer__id',
  PeerIdBase58 = 'peer__idBase58',
  PeerIsAnyJoinedDeals = 'peer__isAnyJoinedDeals',
  ResourceDescription = 'resourceDescription',
  ResourceDescriptionCreatedAt = 'resourceDescription__createdAt',
  ResourceDescriptionId = 'resourceDescription__id',
  ResourceDescriptionMetadata = 'resourceDescription__metadata',
  ResourceDescriptionType = 'resourceDescription__type',
  ResourceDescriptionUpdatedAt = 'resourceDescription__updatedAt',
  Workers = 'workers'
}

export type Peer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Peer_Filter>>>;
  capacityCommitments_?: InputMaybe<CapacityCommitment_Filter>;
  computeUnitsInDeal?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsInDeal_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnits_?: InputMaybe<ComputeUnit_Filter>;
  currentCCCollateralDepositedAt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCCollateralDepositedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCCollateralDepositedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCEndEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCEndEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCEndEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCNextCCFailedEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_gt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_gte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCCNextCCFailedEpoch_lt?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_lte?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_not?: InputMaybe<Scalars['BigInt']['input']>;
  currentCCNextCCFailedEpoch_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentCapacityCommitment?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_?: InputMaybe<CapacityCommitment_Filter>;
  currentCapacityCommitment_contains?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_ends_with?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_gt?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_gte?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_in?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCapacityCommitment_lt?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_lte?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_contains?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCapacityCommitment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_starts_with?: InputMaybe<Scalars['String']['input']>;
  currentCapacityCommitment_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  deleted_not?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idBase58?: InputMaybe<Scalars['String']['input']>;
  idBase58_contains?: InputMaybe<Scalars['String']['input']>;
  idBase58_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  idBase58_ends_with?: InputMaybe<Scalars['String']['input']>;
  idBase58_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  idBase58_gt?: InputMaybe<Scalars['String']['input']>;
  idBase58_gte?: InputMaybe<Scalars['String']['input']>;
  idBase58_in?: InputMaybe<Array<Scalars['String']['input']>>;
  idBase58_lt?: InputMaybe<Scalars['String']['input']>;
  idBase58_lte?: InputMaybe<Scalars['String']['input']>;
  idBase58_not?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_contains?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  idBase58_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  idBase58_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  idBase58_starts_with?: InputMaybe<Scalars['String']['input']>;
  idBase58_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  isAnyJoinedDeals?: InputMaybe<Scalars['Boolean']['input']>;
  isAnyJoinedDeals_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isAnyJoinedDeals_not?: InputMaybe<Scalars['Boolean']['input']>;
  isAnyJoinedDeals_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  joinedDeals_?: InputMaybe<DealToPeer_Filter>;
  offer?: InputMaybe<Scalars['String']['input']>;
  offer_?: InputMaybe<Offer_Filter>;
  offer_contains?: InputMaybe<Scalars['String']['input']>;
  offer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_gt?: InputMaybe<Scalars['String']['input']>;
  offer_gte?: InputMaybe<Scalars['String']['input']>;
  offer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_lt?: InputMaybe<Scalars['String']['input']>;
  offer_lte?: InputMaybe<Scalars['String']['input']>;
  offer_not?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains?: InputMaybe<Scalars['String']['input']>;
  offer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with?: InputMaybe<Scalars['String']['input']>;
  offer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<Peer_Filter>>>;
  peerResources_?: InputMaybe<PeerResource_Filter>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum Peer_OrderBy {
  CapacityCommitments = 'capacityCommitments',
  ComputeUnits = 'computeUnits',
  ComputeUnitsInDeal = 'computeUnitsInDeal',
  ComputeUnitsTotal = 'computeUnitsTotal',
  CurrentCcCollateralDepositedAt = 'currentCCCollateralDepositedAt',
  CurrentCcEndEpoch = 'currentCCEndEpoch',
  CurrentCcNextCcFailedEpoch = 'currentCCNextCCFailedEpoch',
  CurrentCapacityCommitment = 'currentCapacityCommitment',
  CurrentCapacityCommitmentActiveUnitCount = 'currentCapacityCommitment__activeUnitCount',
  CurrentCapacityCommitmentCcRewardsTotal = 'currentCapacityCommitment__ccRewardsTotal',
  CurrentCapacityCommitmentCcRewardsUnlocked = 'currentCapacityCommitment__ccRewardsUnlocked',
  CurrentCapacityCommitmentCcRewardsWithdrawn = 'currentCapacityCommitment__ccRewardsWithdrawn',
  CurrentCapacityCommitmentCollateralPerUnit = 'currentCapacityCommitment__collateralPerUnit',
  CurrentCapacityCommitmentCollateralWithdrawn = 'currentCapacityCommitment__collateralWithdrawn',
  CurrentCapacityCommitmentComputeUnitsCount = 'currentCapacityCommitment__computeUnitsCount',
  CurrentCapacityCommitmentCreatedAt = 'currentCapacityCommitment__createdAt',
  CurrentCapacityCommitmentDealStakerRewardsTotal = 'currentCapacityCommitment__dealStakerRewardsTotal',
  CurrentCapacityCommitmentDealStakerRewardsUnlocked = 'currentCapacityCommitment__dealStakerRewardsUnlocked',
  CurrentCapacityCommitmentDealStakerRewardsWithdrawn = 'currentCapacityCommitment__dealStakerRewardsWithdrawn',
  CurrentCapacityCommitmentDeleted = 'currentCapacityCommitment__deleted',
  CurrentCapacityCommitmentDepositedAt = 'currentCapacityCommitment__depositedAt',
  CurrentCapacityCommitmentDuration = 'currentCapacityCommitment__duration',
  CurrentCapacityCommitmentEndEpoch = 'currentCapacityCommitment__endEpoch',
  CurrentCapacityCommitmentExitedUnitCount = 'currentCapacityCommitment__exitedUnitCount',
  CurrentCapacityCommitmentFailedEpoch = 'currentCapacityCommitment__failedEpoch',
  CurrentCapacityCommitmentId = 'currentCapacityCommitment__id',
  CurrentCapacityCommitmentNextAdditionalActiveUnitCount = 'currentCapacityCommitment__nextAdditionalActiveUnitCount',
  CurrentCapacityCommitmentNextCcFailedEpoch = 'currentCapacityCommitment__nextCCFailedEpoch',
  CurrentCapacityCommitmentRequiredCollateral = 'currentCapacityCommitment__requiredCollateral',
  CurrentCapacityCommitmentRewardDelegatorRate = 'currentCapacityCommitment__rewardDelegatorRate',
  CurrentCapacityCommitmentRewardRatePerCollateral = 'currentCapacityCommitment__rewardRatePerCollateral',
  CurrentCapacityCommitmentSnapshotEpoch = 'currentCapacityCommitment__snapshotEpoch',
  CurrentCapacityCommitmentStartEpoch = 'currentCapacityCommitment__startEpoch',
  CurrentCapacityCommitmentStatus = 'currentCapacityCommitment__status',
  CurrentCapacityCommitmentSubmittedProofsCount = 'currentCapacityCommitment__submittedProofsCount',
  CurrentCapacityCommitmentTotalCollateral = 'currentCapacityCommitment__totalCollateral',
  CurrentCapacityCommitmentTotalFailCount = 'currentCapacityCommitment__totalFailCount',
  CurrentCapacityCommitmentWithdrawnAt = 'currentCapacityCommitment__withdrawnAt',
  Deleted = 'deleted',
  Id = 'id',
  IdBase58 = 'idBase58',
  IsAnyJoinedDeals = 'isAnyJoinedDeals',
  JoinedDeals = 'joinedDeals',
  Offer = 'offer',
  OfferComputeUnitsAvailable = 'offer__computeUnitsAvailable',
  OfferComputeUnitsTotal = 'offer__computeUnitsTotal',
  OfferCreatedAt = 'offer__createdAt',
  OfferDeleted = 'offer__deleted',
  OfferId = 'offer__id',
  OfferMaxProtocolVersion = 'offer__maxProtocolVersion',
  OfferMinProtocolVersion = 'offer__minProtocolVersion',
  OfferPricePerEpoch = 'offer__pricePerEpoch',
  OfferUpdatedAt = 'offer__updatedAt',
  PeerResources = 'peerResources',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs'
}

export type PoolBalance = {
  __typename?: 'PoolBalance';
  balance: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type PoolBalance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolBalance_Filter>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<PoolBalance_Filter>>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum PoolBalance_OrderBy {
  Balance = 'balance',
  Id = 'id',
  Token = 'token'
}

export type PoolTopUpTx = {
  __typename?: 'PoolTopUpTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
};

export type PoolTopUpTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<PoolTopUpTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<PoolTopUpTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum PoolTopUpTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx'
}

export type PoolWithdrawTx = {
  __typename?: 'PoolWithdrawTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
};

export type PoolWithdrawTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<PoolWithdrawTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<PoolWithdrawTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum PoolWithdrawTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx'
}

export type Provider = {
  __typename?: 'Provider';
  approved: Scalars['Boolean']['output'];
  computeUnitsInDeal: Scalars['Int']['output'];
  computeUnitsTotal: Scalars['Int']['output'];
  createdAt: Scalars['BigInt']['output'];
  failedCCs: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offers?: Maybe<Array<Offer>>;
  peerCount: Scalars['Int']['output'];
  peers?: Maybe<Array<Peer>>;
  /** Is provider registered in the network (if false possibly it is only mentioned or global-whitelisted). */
  registered: Scalars['Boolean']['output'];
  totalCCs: Scalars['Int']['output'];
};


export type ProviderOffersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Offer_Filter>;
};


export type ProviderPeersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Peer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Peer_Filter>;
};

export type Provider_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Provider_Filter>>>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  approved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  approved_not?: InputMaybe<Scalars['Boolean']['input']>;
  approved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  computeUnitsInDeal?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsInDeal_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsInDeal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  computeUnitsTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not?: InputMaybe<Scalars['Int']['input']>;
  computeUnitsTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  failedCCs?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_gt?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_gte?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failedCCs_lt?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_lte?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_not?: InputMaybe<Scalars['Int']['input']>;
  failedCCs_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offers_?: InputMaybe<Offer_Filter>;
  or?: InputMaybe<Array<InputMaybe<Provider_Filter>>>;
  peerCount?: InputMaybe<Scalars['Int']['input']>;
  peerCount_gt?: InputMaybe<Scalars['Int']['input']>;
  peerCount_gte?: InputMaybe<Scalars['Int']['input']>;
  peerCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  peerCount_lt?: InputMaybe<Scalars['Int']['input']>;
  peerCount_lte?: InputMaybe<Scalars['Int']['input']>;
  peerCount_not?: InputMaybe<Scalars['Int']['input']>;
  peerCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  peers_?: InputMaybe<Peer_Filter>;
  registered?: InputMaybe<Scalars['Boolean']['input']>;
  registered_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  registered_not?: InputMaybe<Scalars['Boolean']['input']>;
  registered_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  totalCCs?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCCs_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_not?: InputMaybe<Scalars['Int']['input']>;
  totalCCs_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum Provider_OrderBy {
  Approved = 'approved',
  ComputeUnitsInDeal = 'computeUnitsInDeal',
  ComputeUnitsTotal = 'computeUnitsTotal',
  CreatedAt = 'createdAt',
  FailedCCs = 'failedCCs',
  Id = 'id',
  Name = 'name',
  Offers = 'offers',
  PeerCount = 'peerCount',
  Peers = 'peers',
  Registered = 'registered',
  TotalCCs = 'totalCCs'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  capacityCommitment?: Maybe<CapacityCommitment>;
  capacityCommitmentStatsPerEpoch?: Maybe<CapacityCommitmentStatsPerEpoch>;
  capacityCommitmentStatsPerEpoches: Array<CapacityCommitmentStatsPerEpoch>;
  capacityCommitmentToComputeUnit?: Maybe<CapacityCommitmentToComputeUnit>;
  capacityCommitmentToComputeUnits: Array<CapacityCommitmentToComputeUnit>;
  capacityCommitments: Array<CapacityCommitment>;
  capacityStorage?: Maybe<CapacityStorage>;
  capacityStorages: Array<CapacityStorage>;
  cccollateral?: Maybe<CcCollateral>;
  cccollaterals: Array<CcCollateral>;
  cccount?: Maybe<CcCount>;
  cccounts: Array<CcCount>;
  ccsReward?: Maybe<CCsReward>;
  ccsRewards: Array<CCsReward>;
  computeUnit?: Maybe<ComputeUnit>;
  computeUnitStat?: Maybe<ComputeUnitStat>;
  computeUnitStats: Array<ComputeUnitStat>;
  computeUnits: Array<ComputeUnit>;
  coordinatorSetTx?: Maybe<CoordinatorSetTx>;
  coordinatorSetTxes: Array<CoordinatorSetTx>;
  currentCoordinator?: Maybe<CurrentCoordinator>;
  currentCoordinators: Array<CurrentCoordinator>;
  datacenter?: Maybe<Datacenter>;
  datacenterStorage?: Maybe<DatacenterStorage>;
  datacenterStorages: Array<DatacenterStorage>;
  datacenters: Array<Datacenter>;
  deal?: Maybe<Deal>;
  dealBalanceTx?: Maybe<DealBalanceTx>;
  dealBalanceTxes: Array<DealBalanceTx>;
  dealToEffector?: Maybe<DealToEffector>;
  dealToEffectors: Array<DealToEffector>;
  dealToPeer?: Maybe<DealToPeer>;
  dealToPeers: Array<DealToPeer>;
  dealToProvidersAccess?: Maybe<DealToProvidersAccess>;
  dealToProvidersAccesses: Array<DealToProvidersAccess>;
  deals: Array<Deal>;
  effector?: Maybe<Effector>;
  effectors: Array<Effector>;
  epochStatistic?: Maybe<EpochStatistic>;
  epochStatistics: Array<EpochStatistic>;
  freeTotalBalance?: Maybe<FreeTotalBalance>;
  freeTotalBalances: Array<FreeTotalBalance>;
  freezeHistoryTx?: Maybe<FreezeHistoryTx>;
  freezeHistoryTxes: Array<FreezeHistoryTx>;
  fundedFromLimboTx?: Maybe<FundedFromLimboTx>;
  fundedFromLimboTxes: Array<FundedFromLimboTx>;
  graphNetwork?: Maybe<GraphNetwork>;
  graphNetworks: Array<GraphNetwork>;
  limboTotalBalance?: Maybe<LimboTotalBalance>;
  limboTotalBalances: Array<LimboTotalBalance>;
  offer?: Maybe<Offer>;
  offerResource?: Maybe<OfferResource>;
  offerResources: Array<OfferResource>;
  offerToEffector?: Maybe<OfferToEffector>;
  offerToEffectors: Array<OfferToEffector>;
  offers: Array<Offer>;
  peer?: Maybe<Peer>;
  peerResource?: Maybe<PeerResource>;
  peerResources: Array<PeerResource>;
  peers: Array<Peer>;
  poolBalance?: Maybe<PoolBalance>;
  poolBalances: Array<PoolBalance>;
  poolTopUpTx?: Maybe<PoolTopUpTx>;
  poolTopUpTxes: Array<PoolTopUpTx>;
  poolWithdrawTx?: Maybe<PoolWithdrawTx>;
  poolWithdrawTxes: Array<PoolWithdrawTx>;
  provider?: Maybe<Provider>;
  providers: Array<Provider>;
  refundedTx?: Maybe<RefundedTx>;
  refundedTxes: Array<RefundedTx>;
  resourceDescription?: Maybe<ResourceDescription>;
  resourceDescriptions: Array<ResourceDescription>;
  resourceRequirements?: Maybe<ResourceRequirements>;
  resourceRequirements_collection: Array<ResourceRequirements>;
  sentToLimboTx?: Maybe<SentToLimboTx>;
  sentToLimboTxes: Array<SentToLimboTx>;
  staker?: Maybe<Staker>;
  stakers: Array<Staker>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  userBalance?: Maybe<UserBalance>;
  userBalanceTopUpTx?: Maybe<UserBalanceTopUpTx>;
  userBalanceTopUpTxes: Array<UserBalanceTopUpTx>;
  userBalanceTx?: Maybe<UserBalanceTx>;
  userBalanceTxes: Array<UserBalanceTx>;
  userBalances: Array<UserBalance>;
  users: Array<User>;
  withdrawFromLimboTx?: Maybe<WithdrawFromLimboTx>;
  withdrawFromLimboTxes: Array<WithdrawFromLimboTx>;
  worker?: Maybe<Worker>;
  workerToResource?: Maybe<WorkerToResource>;
  workerToResources: Array<WorkerToResource>;
  workers: Array<Worker>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryCapacityCommitmentArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCapacityCommitmentStatsPerEpochArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCapacityCommitmentStatsPerEpochesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitmentStatsPerEpoch_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitmentStatsPerEpoch_Filter>;
};


export type QueryCapacityCommitmentToComputeUnitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCapacityCommitmentToComputeUnitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitmentToComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitmentToComputeUnit_Filter>;
};


export type QueryCapacityCommitmentsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitment_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitment_Filter>;
};


export type QueryCapacityStorageArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCapacityStoragesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityStorage_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityStorage_Filter>;
};


export type QueryCccollateralArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCccollateralsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CcCollateral_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CcCollateral_Filter>;
};


export type QueryCccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CcCount_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CcCount_Filter>;
};


export type QueryCcsRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCcsRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CCsReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CCsReward_Filter>;
};


export type QueryComputeUnitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryComputeUnitStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryComputeUnitStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnitStat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ComputeUnitStat_Filter>;
};


export type QueryComputeUnitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ComputeUnit_Filter>;
};


export type QueryCoordinatorSetTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCoordinatorSetTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CoordinatorSetTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CoordinatorSetTx_Filter>;
};


export type QueryCurrentCoordinatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCurrentCoordinatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CurrentCoordinator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CurrentCoordinator_Filter>;
};


export type QueryDatacenterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDatacenterStorageArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDatacenterStoragesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DatacenterStorage_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DatacenterStorage_Filter>;
};


export type QueryDatacentersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Datacenter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Datacenter_Filter>;
};


export type QueryDealArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDealBalanceTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDealBalanceTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealBalanceTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealBalanceTx_Filter>;
};


export type QueryDealToEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDealToEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToEffector_Filter>;
};


export type QueryDealToPeerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDealToPeersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToPeer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToPeer_Filter>;
};


export type QueryDealToProvidersAccessArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDealToProvidersAccessesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToProvidersAccess_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToProvidersAccess_Filter>;
};


export type QueryDealsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Deal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Deal_Filter>;
};


export type QueryEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Effector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Effector_Filter>;
};


export type QueryEpochStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEpochStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EpochStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<EpochStatistic_Filter>;
};


export type QueryFreeTotalBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFreeTotalBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FreeTotalBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FreeTotalBalance_Filter>;
};


export type QueryFreezeHistoryTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFreezeHistoryTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FreezeHistoryTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FreezeHistoryTx_Filter>;
};


export type QueryFundedFromLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFundedFromLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FundedFromLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundedFromLimboTx_Filter>;
};


export type QueryGraphNetworkArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGraphNetworksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GraphNetwork_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GraphNetwork_Filter>;
};


export type QueryLimboTotalBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLimboTotalBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LimboTotalBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LimboTotalBalance_Filter>;
};


export type QueryOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferResource_Filter>;
};


export type QueryOfferToEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferToEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferToEffector_Filter>;
};


export type QueryOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Offer_Filter>;
};


export type QueryPeerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPeerResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPeerResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PeerResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PeerResource_Filter>;
};


export type QueryPeersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Peer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Peer_Filter>;
};


export type QueryPoolBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolBalance_Filter>;
};


export type QueryPoolTopUpTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolTopUpTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolTopUpTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTopUpTx_Filter>;
};


export type QueryPoolWithdrawTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolWithdrawTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolWithdrawTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolWithdrawTx_Filter>;
};


export type QueryProviderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProvidersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Provider_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Provider_Filter>;
};


export type QueryRefundedTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRefundedTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RefundedTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RefundedTx_Filter>;
};


export type QueryResourceDescriptionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryResourceDescriptionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceDescription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ResourceDescription_Filter>;
};


export type QueryResourceRequirementsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryResourceRequirements_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceRequirements_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ResourceRequirements_Filter>;
};


export type QuerySentToLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySentToLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SentToLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<SentToLimboTx_Filter>;
};


export type QueryStakerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStakersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Staker_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Staker_Filter>;
};


export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type QueryUserArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserBalanceTopUpTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserBalanceTopUpTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalanceTopUpTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalanceTopUpTx_Filter>;
};


export type QueryUserBalanceTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserBalanceTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalanceTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalanceTx_Filter>;
};


export type QueryUserBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalance_Filter>;
};


export type QueryUsersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<User_Filter>;
};


export type QueryWithdrawFromLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryWithdrawFromLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WithdrawFromLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WithdrawFromLimboTx_Filter>;
};


export type QueryWorkerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryWorkerToResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryWorkerToResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkerToResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WorkerToResource_Filter>;
};


export type QueryWorkersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Worker_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Worker_Filter>;
};

export type RefundedTx = {
  __typename?: 'RefundedTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export type RefundedTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<RefundedTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<RefundedTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum RefundedTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx',
  User = 'user'
}

export type ResourceDescription = {
  __typename?: 'ResourceDescription';
  createdAt: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  metadata: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

export type ResourceDescription_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ResourceDescription_Filter>>>;
  createdAt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metadata_contains?: InputMaybe<Scalars['String']['input']>;
  metadata_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  metadata_ends_with?: InputMaybe<Scalars['String']['input']>;
  metadata_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  metadata_gt?: InputMaybe<Scalars['String']['input']>;
  metadata_gte?: InputMaybe<Scalars['String']['input']>;
  metadata_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata_lt?: InputMaybe<Scalars['String']['input']>;
  metadata_lte?: InputMaybe<Scalars['String']['input']>;
  metadata_not?: InputMaybe<Scalars['String']['input']>;
  metadata_not_contains?: InputMaybe<Scalars['String']['input']>;
  metadata_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  metadata_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  metadata_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  metadata_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  metadata_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  metadata_starts_with?: InputMaybe<Scalars['String']['input']>;
  metadata_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<ResourceDescription_Filter>>>;
  type?: InputMaybe<Scalars['Int']['input']>;
  type_gt?: InputMaybe<Scalars['Int']['input']>;
  type_gte?: InputMaybe<Scalars['Int']['input']>;
  type_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  type_lt?: InputMaybe<Scalars['Int']['input']>;
  type_lte?: InputMaybe<Scalars['Int']['input']>;
  type_not?: InputMaybe<Scalars['Int']['input']>;
  type_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum ResourceDescription_OrderBy {
  CreatedAt = 'createdAt',
  Id = 'id',
  Metadata = 'metadata',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ResourceRequirements = {
  __typename?: 'ResourceRequirements';
  deal: Deal;
  id: Scalars['ID']['output'];
  quantity: Scalars['BigInt']['output'];
  resourceDescription: ResourceDescription;
};

export type ResourceRequirements_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ResourceRequirements_Filter>>>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<ResourceRequirements_Filter>>>;
  quantity?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_gt?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_gte?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  quantity_lt?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_lte?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_not?: InputMaybe<Scalars['BigInt']['input']>;
  quantity_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  resourceDescription?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_?: InputMaybe<ResourceDescription_Filter>;
  resourceDescription_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_gte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_lt?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_lte?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceDescription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum ResourceRequirements_OrderBy {
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Id = 'id',
  Quantity = 'quantity',
  ResourceDescription = 'resourceDescription',
  ResourceDescriptionCreatedAt = 'resourceDescription__createdAt',
  ResourceDescriptionId = 'resourceDescription__id',
  ResourceDescriptionMetadata = 'resourceDescription__metadata',
  ResourceDescriptionType = 'resourceDescription__type',
  ResourceDescriptionUpdatedAt = 'resourceDescription__updatedAt'
}

export type SentToLimboTx = {
  __typename?: 'SentToLimboTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export type SentToLimboTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<SentToLimboTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<SentToLimboTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum SentToLimboTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx',
  User = 'user'
}

export type Staker = {
  __typename?: 'Staker';
  fltBalance: Scalars['BigInt']['output'];
  id: Scalars['String']['output'];
  slashedCollateral: Scalars['BigInt']['output'];
  stakedCollateral: Scalars['BigInt']['output'];
  withdrawnCollateral: Scalars['BigInt']['output'];
};

export type Staker_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Staker_Filter>>>;
  fltBalance?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fltBalance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_not?: InputMaybe<Scalars['BigInt']['input']>;
  fltBalance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<Staker_Filter>>>;
  slashedCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  slashedCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  slashedCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakedCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakedCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakedCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnCollateral?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_gt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_gte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  withdrawnCollateral_lt?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_lte?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_not?: InputMaybe<Scalars['BigInt']['input']>;
  withdrawnCollateral_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum Staker_OrderBy {
  FltBalance = 'fltBalance',
  Id = 'id',
  SlashedCollateral = 'slashedCollateral',
  StakedCollateral = 'stakedCollateral',
  WithdrawnCollateral = 'withdrawnCollateral'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  capacityCommitment?: Maybe<CapacityCommitment>;
  capacityCommitmentStatsPerEpoch?: Maybe<CapacityCommitmentStatsPerEpoch>;
  capacityCommitmentStatsPerEpoches: Array<CapacityCommitmentStatsPerEpoch>;
  capacityCommitmentToComputeUnit?: Maybe<CapacityCommitmentToComputeUnit>;
  capacityCommitmentToComputeUnits: Array<CapacityCommitmentToComputeUnit>;
  capacityCommitments: Array<CapacityCommitment>;
  capacityStorage?: Maybe<CapacityStorage>;
  capacityStorages: Array<CapacityStorage>;
  cccollateral?: Maybe<CcCollateral>;
  cccollaterals: Array<CcCollateral>;
  cccount?: Maybe<CcCount>;
  cccounts: Array<CcCount>;
  ccsReward?: Maybe<CCsReward>;
  ccsRewards: Array<CCsReward>;
  computeUnit?: Maybe<ComputeUnit>;
  computeUnitStat?: Maybe<ComputeUnitStat>;
  computeUnitStats: Array<ComputeUnitStat>;
  computeUnits: Array<ComputeUnit>;
  coordinatorSetTx?: Maybe<CoordinatorSetTx>;
  coordinatorSetTxes: Array<CoordinatorSetTx>;
  currentCoordinator?: Maybe<CurrentCoordinator>;
  currentCoordinators: Array<CurrentCoordinator>;
  datacenter?: Maybe<Datacenter>;
  datacenterStorage?: Maybe<DatacenterStorage>;
  datacenterStorages: Array<DatacenterStorage>;
  datacenters: Array<Datacenter>;
  deal?: Maybe<Deal>;
  dealBalanceTx?: Maybe<DealBalanceTx>;
  dealBalanceTxes: Array<DealBalanceTx>;
  dealToEffector?: Maybe<DealToEffector>;
  dealToEffectors: Array<DealToEffector>;
  dealToPeer?: Maybe<DealToPeer>;
  dealToPeers: Array<DealToPeer>;
  dealToProvidersAccess?: Maybe<DealToProvidersAccess>;
  dealToProvidersAccesses: Array<DealToProvidersAccess>;
  deals: Array<Deal>;
  effector?: Maybe<Effector>;
  effectors: Array<Effector>;
  epochStatistic?: Maybe<EpochStatistic>;
  epochStatistics: Array<EpochStatistic>;
  freeTotalBalance?: Maybe<FreeTotalBalance>;
  freeTotalBalances: Array<FreeTotalBalance>;
  freezeHistoryTx?: Maybe<FreezeHistoryTx>;
  freezeHistoryTxes: Array<FreezeHistoryTx>;
  fundedFromLimboTx?: Maybe<FundedFromLimboTx>;
  fundedFromLimboTxes: Array<FundedFromLimboTx>;
  graphNetwork?: Maybe<GraphNetwork>;
  graphNetworks: Array<GraphNetwork>;
  limboTotalBalance?: Maybe<LimboTotalBalance>;
  limboTotalBalances: Array<LimboTotalBalance>;
  offer?: Maybe<Offer>;
  offerResource?: Maybe<OfferResource>;
  offerResources: Array<OfferResource>;
  offerToEffector?: Maybe<OfferToEffector>;
  offerToEffectors: Array<OfferToEffector>;
  offers: Array<Offer>;
  peer?: Maybe<Peer>;
  peerResource?: Maybe<PeerResource>;
  peerResources: Array<PeerResource>;
  peers: Array<Peer>;
  poolBalance?: Maybe<PoolBalance>;
  poolBalances: Array<PoolBalance>;
  poolTopUpTx?: Maybe<PoolTopUpTx>;
  poolTopUpTxes: Array<PoolTopUpTx>;
  poolWithdrawTx?: Maybe<PoolWithdrawTx>;
  poolWithdrawTxes: Array<PoolWithdrawTx>;
  provider?: Maybe<Provider>;
  providers: Array<Provider>;
  refundedTx?: Maybe<RefundedTx>;
  refundedTxes: Array<RefundedTx>;
  resourceDescription?: Maybe<ResourceDescription>;
  resourceDescriptions: Array<ResourceDescription>;
  resourceRequirements?: Maybe<ResourceRequirements>;
  resourceRequirements_collection: Array<ResourceRequirements>;
  sentToLimboTx?: Maybe<SentToLimboTx>;
  sentToLimboTxes: Array<SentToLimboTx>;
  staker?: Maybe<Staker>;
  stakers: Array<Staker>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  userBalance?: Maybe<UserBalance>;
  userBalanceTopUpTx?: Maybe<UserBalanceTopUpTx>;
  userBalanceTopUpTxes: Array<UserBalanceTopUpTx>;
  userBalanceTx?: Maybe<UserBalanceTx>;
  userBalanceTxes: Array<UserBalanceTx>;
  userBalances: Array<UserBalance>;
  users: Array<User>;
  withdrawFromLimboTx?: Maybe<WithdrawFromLimboTx>;
  withdrawFromLimboTxes: Array<WithdrawFromLimboTx>;
  worker?: Maybe<Worker>;
  workerToResource?: Maybe<WorkerToResource>;
  workerToResources: Array<WorkerToResource>;
  workers: Array<Worker>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionCapacityCommitmentArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCapacityCommitmentStatsPerEpochArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCapacityCommitmentStatsPerEpochesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitmentStatsPerEpoch_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitmentStatsPerEpoch_Filter>;
};


export type SubscriptionCapacityCommitmentToComputeUnitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCapacityCommitmentToComputeUnitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitmentToComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitmentToComputeUnit_Filter>;
};


export type SubscriptionCapacityCommitmentsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitment_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityCommitment_Filter>;
};


export type SubscriptionCapacityStorageArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCapacityStoragesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityStorage_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CapacityStorage_Filter>;
};


export type SubscriptionCccollateralArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCccollateralsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CcCollateral_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CcCollateral_Filter>;
};


export type SubscriptionCccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CcCount_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CcCount_Filter>;
};


export type SubscriptionCcsRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCcsRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CCsReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CCsReward_Filter>;
};


export type SubscriptionComputeUnitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionComputeUnitStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionComputeUnitStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnitStat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ComputeUnitStat_Filter>;
};


export type SubscriptionComputeUnitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ComputeUnit_Filter>;
};


export type SubscriptionCoordinatorSetTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCoordinatorSetTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CoordinatorSetTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CoordinatorSetTx_Filter>;
};


export type SubscriptionCurrentCoordinatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCurrentCoordinatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CurrentCoordinator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CurrentCoordinator_Filter>;
};


export type SubscriptionDatacenterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDatacenterStorageArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDatacenterStoragesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DatacenterStorage_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DatacenterStorage_Filter>;
};


export type SubscriptionDatacentersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Datacenter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Datacenter_Filter>;
};


export type SubscriptionDealArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDealBalanceTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDealBalanceTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealBalanceTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealBalanceTx_Filter>;
};


export type SubscriptionDealToEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDealToEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToEffector_Filter>;
};


export type SubscriptionDealToPeerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDealToPeersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToPeer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToPeer_Filter>;
};


export type SubscriptionDealToProvidersAccessArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDealToProvidersAccessesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealToProvidersAccess_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DealToProvidersAccess_Filter>;
};


export type SubscriptionDealsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Deal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Deal_Filter>;
};


export type SubscriptionEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Effector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Effector_Filter>;
};


export type SubscriptionEpochStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEpochStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EpochStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<EpochStatistic_Filter>;
};


export type SubscriptionFreeTotalBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFreeTotalBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FreeTotalBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FreeTotalBalance_Filter>;
};


export type SubscriptionFreezeHistoryTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFreezeHistoryTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FreezeHistoryTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FreezeHistoryTx_Filter>;
};


export type SubscriptionFundedFromLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFundedFromLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FundedFromLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundedFromLimboTx_Filter>;
};


export type SubscriptionGraphNetworkArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGraphNetworksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GraphNetwork_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GraphNetwork_Filter>;
};


export type SubscriptionLimboTotalBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLimboTotalBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LimboTotalBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LimboTotalBalance_Filter>;
};


export type SubscriptionOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferResource_Filter>;
};


export type SubscriptionOfferToEffectorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferToEffectorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OfferToEffector_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferToEffector_Filter>;
};


export type SubscriptionOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Offer_Filter>;
};


export type SubscriptionPeerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPeerResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPeerResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PeerResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PeerResource_Filter>;
};


export type SubscriptionPeersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Peer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Peer_Filter>;
};


export type SubscriptionPoolBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolBalance_Filter>;
};


export type SubscriptionPoolTopUpTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolTopUpTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolTopUpTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTopUpTx_Filter>;
};


export type SubscriptionPoolWithdrawTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolWithdrawTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolWithdrawTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolWithdrawTx_Filter>;
};


export type SubscriptionProviderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProvidersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Provider_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Provider_Filter>;
};


export type SubscriptionRefundedTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRefundedTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RefundedTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RefundedTx_Filter>;
};


export type SubscriptionResourceDescriptionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionResourceDescriptionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceDescription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ResourceDescription_Filter>;
};


export type SubscriptionResourceRequirementsArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionResourceRequirements_CollectionArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceRequirements_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ResourceRequirements_Filter>;
};


export type SubscriptionSentToLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSentToLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SentToLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<SentToLimboTx_Filter>;
};


export type SubscriptionStakerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStakersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Staker_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Staker_Filter>;
};


export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type SubscriptionUserArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserBalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserBalanceTopUpTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserBalanceTopUpTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalanceTopUpTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalanceTopUpTx_Filter>;
};


export type SubscriptionUserBalanceTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserBalanceTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalanceTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalanceTx_Filter>;
};


export type SubscriptionUserBalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserBalance_Filter>;
};


export type SubscriptionUsersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<User_Filter>;
};


export type SubscriptionWithdrawFromLimboTxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionWithdrawFromLimboTxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WithdrawFromLimboTx_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WithdrawFromLimboTx_Filter>;
};


export type SubscriptionWorkerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionWorkerToResourceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionWorkerToResourcesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkerToResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WorkerToResource_Filter>;
};


export type SubscriptionWorkersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Worker_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Worker_Filter>;
};

export type Token = {
  __typename?: 'Token';
  decimals: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  symbol: Scalars['String']['output'];
};

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum Token_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  Symbol = 'symbol'
}

export type User = {
  __typename?: 'User';
  balances: Array<UserBalance>;
  id: Scalars['ID']['output'];
  status: Scalars['Int']['output'];
};


export type UserBalancesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBalance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserBalance_Filter>;
};

export type UserBalance = {
  __typename?: 'UserBalance';
  freeBalance: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  limboBalance: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  user: User;
};

export type UserBalanceTopUpTx = {
  __typename?: 'UserBalanceTopUpTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  topUpId: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export type UserBalanceTopUpTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<UserBalanceTopUpTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<UserBalanceTopUpTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId?: InputMaybe<Scalars['String']['input']>;
  topUpId_contains?: InputMaybe<Scalars['String']['input']>;
  topUpId_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId_ends_with?: InputMaybe<Scalars['String']['input']>;
  topUpId_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId_gt?: InputMaybe<Scalars['String']['input']>;
  topUpId_gte?: InputMaybe<Scalars['String']['input']>;
  topUpId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  topUpId_lt?: InputMaybe<Scalars['String']['input']>;
  topUpId_lte?: InputMaybe<Scalars['String']['input']>;
  topUpId_not?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_contains?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  topUpId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  topUpId_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  topUpId_starts_with?: InputMaybe<Scalars['String']['input']>;
  topUpId_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum UserBalanceTopUpTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  Token = 'token',
  TopUpId = 'topUpId',
  Tx = 'tx',
  User = 'user'
}

export type UserBalanceTx = {
  __typename?: 'UserBalanceTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['Int']['output'];
  target: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: User;
};

export type UserBalanceTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<UserBalanceTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<UserBalanceTx_Filter>>>;
  reason?: InputMaybe<Scalars['Int']['input']>;
  reason_gt?: InputMaybe<Scalars['Int']['input']>;
  reason_gte?: InputMaybe<Scalars['Int']['input']>;
  reason_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reason_lt?: InputMaybe<Scalars['Int']['input']>;
  reason_lte?: InputMaybe<Scalars['Int']['input']>;
  reason_not?: InputMaybe<Scalars['Int']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  target?: InputMaybe<Scalars['String']['input']>;
  target_contains?: InputMaybe<Scalars['String']['input']>;
  target_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  target_ends_with?: InputMaybe<Scalars['String']['input']>;
  target_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  target_gt?: InputMaybe<Scalars['String']['input']>;
  target_gte?: InputMaybe<Scalars['String']['input']>;
  target_in?: InputMaybe<Array<Scalars['String']['input']>>;
  target_lt?: InputMaybe<Scalars['String']['input']>;
  target_lte?: InputMaybe<Scalars['String']['input']>;
  target_not?: InputMaybe<Scalars['String']['input']>;
  target_not_contains?: InputMaybe<Scalars['String']['input']>;
  target_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  target_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  target_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  target_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  target_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  target_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  target_starts_with?: InputMaybe<Scalars['String']['input']>;
  target_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_?: InputMaybe<User_Filter>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum UserBalanceTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Reason = 'reason',
  Target = 'target',
  Timestamp = 'timestamp',
  Token = 'token',
  Tx = 'tx',
  User = 'user',
  UserId = 'user__id',
  UserStatus = 'user__status'
}

export type UserBalance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserBalance_Filter>>>;
  freeBalance?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  freeBalance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_not?: InputMaybe<Scalars['BigInt']['input']>;
  freeBalance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  limboBalance?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  limboBalance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_not?: InputMaybe<Scalars['BigInt']['input']>;
  limboBalance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  or?: InputMaybe<Array<InputMaybe<UserBalance_Filter>>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_?: InputMaybe<User_Filter>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum UserBalance_OrderBy {
  FreeBalance = 'freeBalance',
  Id = 'id',
  LimboBalance = 'limboBalance',
  Token = 'token',
  User = 'user',
  UserId = 'user__id',
  UserStatus = 'user__status'
}

export type User_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_Filter>>>;
  balances_?: InputMaybe<UserBalance_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<User_Filter>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum User_OrderBy {
  Balances = 'balances',
  Id = 'id',
  Status = 'status'
}

export type WithdrawFromLimboTx = {
  __typename?: 'WithdrawFromLimboTx';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  initiator: Scalars['String']['output'];
  timestamp: Scalars['BigInt']['output'];
  to: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tx: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export type WithdrawFromLimboTx_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<WithdrawFromLimboTx_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<WithdrawFromLimboTx_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
  to_contains?: InputMaybe<Scalars['String']['input']>;
  to_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_gt?: InputMaybe<Scalars['String']['input']>;
  to_gte?: InputMaybe<Scalars['String']['input']>;
  to_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_lt?: InputMaybe<Scalars['String']['input']>;
  to_lte?: InputMaybe<Scalars['String']['input']>;
  to_not?: InputMaybe<Scalars['String']['input']>;
  to_not_contains?: InputMaybe<Scalars['String']['input']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx?: InputMaybe<Scalars['String']['input']>;
  tx_contains?: InputMaybe<Scalars['String']['input']>;
  tx_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_gt?: InputMaybe<Scalars['String']['input']>;
  tx_gte?: InputMaybe<Scalars['String']['input']>;
  tx_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_lt?: InputMaybe<Scalars['String']['input']>;
  tx_lte?: InputMaybe<Scalars['String']['input']>;
  tx_not?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains?: InputMaybe<Scalars['String']['input']>;
  tx_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tx_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with?: InputMaybe<Scalars['String']['input']>;
  tx_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_contains?: InputMaybe<Scalars['String']['input']>;
  user_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_gt?: InputMaybe<Scalars['String']['input']>;
  user_gte?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_lt?: InputMaybe<Scalars['String']['input']>;
  user_lte?: InputMaybe<Scalars['String']['input']>;
  user_not?: InputMaybe<Scalars['String']['input']>;
  user_not_contains?: InputMaybe<Scalars['String']['input']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  user_starts_with?: InputMaybe<Scalars['String']['input']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum WithdrawFromLimboTx_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Initiator = 'initiator',
  Timestamp = 'timestamp',
  To = 'to',
  Token = 'token',
  Tx = 'tx',
  User = 'user'
}

export type Worker = {
  __typename?: 'Worker';
  computeUnits?: Maybe<Array<ComputeUnit>>;
  deal: Deal;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  offchainWorkerId?: Maybe<Scalars['String']['output']>;
  peer: Peer;
  provider: Provider;
  resources?: Maybe<Array<WorkerToResource>>;
};


export type WorkerComputeUnitsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ComputeUnit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComputeUnit_Filter>;
};


export type WorkerResourcesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkerToResource_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkerToResource_Filter>;
};

export type WorkerToResource = {
  __typename?: 'WorkerToResource';
  id: Scalars['ID']['output'];
  peerResource: PeerResource;
  resourceRequirements: ResourceRequirements;
  worker: Worker;
};

export type WorkerToResource_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<WorkerToResource_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<WorkerToResource_Filter>>>;
  peerResource?: InputMaybe<Scalars['String']['input']>;
  peerResource_?: InputMaybe<PeerResource_Filter>;
  peerResource_contains?: InputMaybe<Scalars['String']['input']>;
  peerResource_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peerResource_ends_with?: InputMaybe<Scalars['String']['input']>;
  peerResource_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peerResource_gt?: InputMaybe<Scalars['String']['input']>;
  peerResource_gte?: InputMaybe<Scalars['String']['input']>;
  peerResource_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peerResource_lt?: InputMaybe<Scalars['String']['input']>;
  peerResource_lte?: InputMaybe<Scalars['String']['input']>;
  peerResource_not?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_contains?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peerResource_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peerResource_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peerResource_starts_with?: InputMaybe<Scalars['String']['input']>;
  peerResource_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_?: InputMaybe<ResourceRequirements_Filter>;
  resourceRequirements_contains?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_gt?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_gte?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceRequirements_lt?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_lte?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_contains?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceRequirements_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_starts_with?: InputMaybe<Scalars['String']['input']>;
  resourceRequirements_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker?: InputMaybe<Scalars['String']['input']>;
  worker_?: InputMaybe<Worker_Filter>;
  worker_contains?: InputMaybe<Scalars['String']['input']>;
  worker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_ends_with?: InputMaybe<Scalars['String']['input']>;
  worker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_gt?: InputMaybe<Scalars['String']['input']>;
  worker_gte?: InputMaybe<Scalars['String']['input']>;
  worker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  worker_lt?: InputMaybe<Scalars['String']['input']>;
  worker_lte?: InputMaybe<Scalars['String']['input']>;
  worker_not?: InputMaybe<Scalars['String']['input']>;
  worker_not_contains?: InputMaybe<Scalars['String']['input']>;
  worker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  worker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  worker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  worker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  worker_starts_with?: InputMaybe<Scalars['String']['input']>;
  worker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum WorkerToResource_OrderBy {
  Id = 'id',
  PeerResource = 'peerResource',
  PeerResourceAvailableSupply = 'peerResource__availableSupply',
  PeerResourceDetails = 'peerResource__details',
  PeerResourceId = 'peerResource__id',
  PeerResourceMaxSupply = 'peerResource__maxSupply',
  ResourceRequirements = 'resourceRequirements',
  ResourceRequirementsId = 'resourceRequirements__id',
  ResourceRequirementsQuantity = 'resourceRequirements__quantity',
  Worker = 'worker',
  WorkerDeleted = 'worker__deleted',
  WorkerId = 'worker__id',
  WorkerOffchainWorkerId = 'worker__offchainWorkerId'
}

export type Worker_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Worker_Filter>>>;
  computeUnits_?: InputMaybe<ComputeUnit_Filter>;
  deal?: InputMaybe<Scalars['String']['input']>;
  deal_?: InputMaybe<Deal_Filter>;
  deal_contains?: InputMaybe<Scalars['String']['input']>;
  deal_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_gt?: InputMaybe<Scalars['String']['input']>;
  deal_gte?: InputMaybe<Scalars['String']['input']>;
  deal_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_lt?: InputMaybe<Scalars['String']['input']>;
  deal_lte?: InputMaybe<Scalars['String']['input']>;
  deal_not?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains?: InputMaybe<Scalars['String']['input']>;
  deal_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deal_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with?: InputMaybe<Scalars['String']['input']>;
  deal_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  deleted_not?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  offchainWorkerId?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_contains?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_gt?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_gte?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offchainWorkerId_lt?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_lte?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  offchainWorkerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  offchainWorkerId_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<Worker_Filter>>>;
  peer?: InputMaybe<Scalars['String']['input']>;
  peer_?: InputMaybe<Peer_Filter>;
  peer_contains?: InputMaybe<Scalars['String']['input']>;
  peer_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_gt?: InputMaybe<Scalars['String']['input']>;
  peer_gte?: InputMaybe<Scalars['String']['input']>;
  peer_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_lt?: InputMaybe<Scalars['String']['input']>;
  peer_lte?: InputMaybe<Scalars['String']['input']>;
  peer_not?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains?: InputMaybe<Scalars['String']['input']>;
  peer_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  peer_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with?: InputMaybe<Scalars['String']['input']>;
  peer_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_?: InputMaybe<Provider_Filter>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_gt?: InputMaybe<Scalars['String']['input']>;
  provider_gte?: InputMaybe<Scalars['String']['input']>;
  provider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_lt?: InputMaybe<Scalars['String']['input']>;
  provider_lte?: InputMaybe<Scalars['String']['input']>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  provider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resources_?: InputMaybe<WorkerToResource_Filter>;
};

export enum Worker_OrderBy {
  ComputeUnits = 'computeUnits',
  Deal = 'deal',
  DealAppCid = 'deal__appCID',
  DealCreatedAt = 'deal__createdAt',
  DealCuCountPerWorker = 'deal__cuCountPerWorker',
  DealDepositedSum = 'deal__depositedSum',
  DealId = 'deal__id',
  DealMatchedAt = 'deal__matchedAt',
  DealMatchedWorkersCurrentCount = 'deal__matchedWorkersCurrentCount',
  DealMaxPaidEpoch = 'deal__maxPaidEpoch',
  DealMaxWorkersPerProvider = 'deal__maxWorkersPerProvider',
  DealMinWorkers = 'deal__minWorkers',
  DealOwner = 'deal__owner',
  DealPricePerCuPerEpoch = 'deal__pricePerCuPerEpoch',
  DealPricePerEpoch = 'deal__pricePerEpoch',
  DealProvidersAccessType = 'deal__providersAccessType',
  DealRegisteredWorkersCurrentCount = 'deal__registeredWorkersCurrentCount',
  DealTargetWorkers = 'deal__targetWorkers',
  DealVersion = 'deal__version',
  DealWithdrawalSum = 'deal__withdrawalSum',
  Deleted = 'deleted',
  Id = 'id',
  OffchainWorkerId = 'offchainWorkerId',
  Peer = 'peer',
  PeerComputeUnitsInDeal = 'peer__computeUnitsInDeal',
  PeerComputeUnitsTotal = 'peer__computeUnitsTotal',
  PeerCurrentCcCollateralDepositedAt = 'peer__currentCCCollateralDepositedAt',
  PeerCurrentCcEndEpoch = 'peer__currentCCEndEpoch',
  PeerCurrentCcNextCcFailedEpoch = 'peer__currentCCNextCCFailedEpoch',
  PeerDeleted = 'peer__deleted',
  PeerId = 'peer__id',
  PeerIdBase58 = 'peer__idBase58',
  PeerIsAnyJoinedDeals = 'peer__isAnyJoinedDeals',
  Provider = 'provider',
  ProviderApproved = 'provider__approved',
  ProviderComputeUnitsInDeal = 'provider__computeUnitsInDeal',
  ProviderComputeUnitsTotal = 'provider__computeUnitsTotal',
  ProviderCreatedAt = 'provider__createdAt',
  ProviderFailedCCs = 'provider__failedCCs',
  ProviderId = 'provider__id',
  ProviderName = 'provider__name',
  ProviderPeerCount = 'provider__peerCount',
  ProviderRegistered = 'provider__registered',
  ProviderTotalCCs = 'provider__totalCCs',
  Resources = 'resources'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']['output']>;
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type CcCountQueryVariables = Exact<{ [key: string]: never; }>;


export type CcCountQuery = { __typename?: 'Query', cccount?: { __typename?: 'CCCount', id: string, ccInactiveCount: number, ccActiveCount: number, ccWaitDelegationCount: number, ccWaitStartCount: number, ccFailedCount: number, ccRemovedCount: number } | null };

export type CapacityCommitmentsQueryVariables = Exact<{
  filters?: InputMaybe<CapacityCommitment_Filter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapacityCommitment_OrderBy>;
  orderType?: InputMaybe<OrderDirection>;
}>;


export type CapacityCommitmentsQuery = { __typename?: 'Query', capacityCommitments: Array<{ __typename?: 'CapacityCommitment', id: string, status?: CapacityCommitmentStatus | null, duration: any, createdAt: any, startEpoch: any, endEpoch: any, collateralWithdrawn: boolean, requiredCollateral: any, rewardDelegatorRate: any, rewardRatePerCollateral: any, totalCollateral: any, computeUnitsCount: number, totalFailCount: number, collateralPerUnit: any, provider: { __typename?: 'Provider', id: string, name: string, approved: boolean, totalCCs: number, createdAt: any }, delegator?: { __typename?: 'Staker', id: string } | null, peer: { __typename?: 'Peer', id: string } }>, graphNetworks: Array<{ __typename?: 'GraphNetwork', id: string, capacityCommitmentsTotal: any, fltPrice: any, usdTargetRevenuePerEpoch: any, slashingRate?: any | null, coreEpochDuration?: number | null, initTimestamp?: number | null, capacityMaxFailedRatio?: number | null, corePrecision?: any | null }> };

export type CCsRewardQueryVariables = Exact<{ [key: string]: never; }>;


export type CCsRewardQuery = { __typename?: 'Query', ccsReward?: { __typename?: 'CCsReward', id: string, stakerTotalCapacityRewards: any, stakerUnlockedCapacityRewards: any, stakerWithdrawnCapacityRewards: any, totalCapacityRewards: any, totalDealStakerRewards: any, unlockedCapacityRewards: any, unlockedDealStakerRewards: any, withdrawnCapacityRewards: any, withdrawnDealStakerRewards: any } | null };

export type ConstantsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConstantsQuery = { __typename?: 'Query', graphNetworks: Array<{ __typename?: 'GraphNetwork', id: string, fltPrice: any, usdTargetRevenuePerEpoch: any, coreEpochDuration?: number | null, initTimestamp?: number | null, minRequiredProofsPerEpoch?: number | null, corePrecision?: any | null, capacityMaxFailedRatio?: number | null, slashingRate?: any | null }> };


export const CcCountDocument = gql`
    query CCCount {
  cccount(id: "total") {
    id
    ccInactiveCount
    ccActiveCount
    ccWaitDelegationCount
    ccWaitStartCount
    ccFailedCount
    ccRemovedCount
  }
}
    `;
export const CapacityCommitmentsDocument = gql`
    query CapacityCommitments($filters: CapacityCommitment_filter, $offset: Int, $limit: Int, $orderBy: CapacityCommitment_orderBy, $orderType: OrderDirection) {
  capacityCommitments(
    where: $filters
    first: $limit
    skip: $offset
    orderBy: $orderBy
    orderDirection: $orderType
  ) {
    id
    provider {
      id
      name
      approved
      totalCCs
      createdAt
    }
    status
    duration
    createdAt
    startEpoch
    endEpoch
    collateralWithdrawn
    requiredCollateral
    rewardDelegatorRate
    rewardRatePerCollateral
    delegator {
      id
    }
    peer {
      id
    }
    totalCollateral
    computeUnitsCount
    totalFailCount
    collateralPerUnit
  }
  graphNetworks(first: 1) {
    id
    capacityCommitmentsTotal
    fltPrice
    usdTargetRevenuePerEpoch
    slashingRate
    coreEpochDuration
    initTimestamp
    capacityMaxFailedRatio
    corePrecision
  }
}
    `;
export const CCsRewardDocument = gql`
    query CCsReward {
  ccsReward(id: "total") {
    id
    stakerTotalCapacityRewards
    stakerUnlockedCapacityRewards
    stakerWithdrawnCapacityRewards
    totalCapacityRewards
    totalDealStakerRewards
    unlockedCapacityRewards
    unlockedDealStakerRewards
    withdrawnCapacityRewards
    withdrawnDealStakerRewards
  }
}
    `;
export const ConstantsDocument = gql`
    query Constants {
  graphNetworks(first: 1) {
    id
    fltPrice
    usdTargetRevenuePerEpoch
    coreEpochDuration
    initTimestamp
    minRequiredProofsPerEpoch
    corePrecision
    capacityMaxFailedRatio
    slashingRate
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();
const CcCountDocumentString = print(CcCountDocument);
const CapacityCommitmentsDocumentString = print(CapacityCommitmentsDocument);
const CCsRewardDocumentString = print(CCsRewardDocument);
const ConstantsDocumentString = print(ConstantsDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CCCount(variables?: CcCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CcCountQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CcCountQuery>(CcCountDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CCCount', 'query', variables);
    },
    CapacityCommitments(variables?: CapacityCommitmentsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CapacityCommitmentsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CapacityCommitmentsQuery>(CapacityCommitmentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CapacityCommitments', 'query', variables);
    },
    CCsReward(variables?: CCsRewardQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: CCsRewardQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CCsRewardQuery>(CCsRewardDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CCsReward', 'query', variables);
    },
    Constants(variables?: ConstantsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: ConstantsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ConstantsQuery>(ConstantsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Constants', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;